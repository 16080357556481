import React from 'react';
import ReactDOM from 'react-dom';
import mapboxgl from 'mapbox-gl';
import './index.css';
import SearchResultCard from './SearchResultCard';
import Stars from './Stars';
import './SearchResults.css';

import App from './App';
import * as serviceWorker from './serviceWorker';

mapboxgl.accessToken = 'pk.eyJ1IjoibmFpbHVqMjciLCJhIjoiY2tleWVucDAxMGRxYjJybDU1c252YzFwayJ9.XMPMTxmObzFBrKnQTaGNYw';

class Reviews extends React.Component {

  calculatePages(carriers_count) {
    const number_of_pages = Math.ceil(carriers_count / 10.0);
    return number_of_pages;
  }

  render() {
    var maxPages = this.calculatePages(this.props.reviews?.length);
    return (
      <div style={{minHeight: '400px'}}>
        <ul>
          {this.props.reviews?.map((review) => <li className="review">
            <Stars numberOfStars={review.stars} /> <span style={{verticalAlign: 'super', marginLeft: '10px'}}>{new Date(review.created_at.split(" GMT-0500")[0]).toDateString()}</span>
            <p style={{paddingBottom: '25px', borderBottom:'solid 1px #C0C0C0', width: '100%'}}> {review.comment} </p>
            </li>
          )}
          {
            (this.props.reviews && this.props.reviews.length === 0) &&
            (<p> No reviews! Be the first to leave an anonymous review here. </p>)
          }

        </ul>
        <p>{this.props.carriers_count} </p>
        {(this.props.reviews && this.props.reviews.length > 10) && (<p>
          <span onClick={() => this.props.onPageChange(this.props.page - 1 <= 1 ? 1 : this.props.page - 1 )}>
          Previous </span> {this.props.page} / {maxPages}
          <span onClick={() => this.props.onPageChange(this.props.page + 1 >= maxPages ? maxPages : this.props.page + 1 )}> Next </span>
          </p>)
        }
      </div>
    )
  }
}

export default Reviews;
