/* global woopra */

import React from 'react';
import ReactDOM from 'react-dom';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";

import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import SearchResultCard from './SearchResultCard';
import './SignupDialog.css';
import {API_HOST_NAME} from './Environment.js';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

class Profile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: {},
      pages: [],
      favorite_safer_pages: []
    }
  }

  componentDidMount() {
    fetch(`${API_HOST_NAME()}/users/1`, {
      method: 'GET',
      headers: {'Content-Type': 'application/json',
      'uid': localStorage.getItem('uid'),
      'access-token': localStorage.getItem('access-token'),
      'expiry': localStorage.getItem('expiry'),
      'client': localStorage.getItem('client')
    }
    })
      .then(response => {
        return response.json()})
      .then(json => {

        this.setState( (state, props) => {
          return json
        });
      });
  }


  render() {
    return (
    <div style={{display: 'flex', flexDirection: 'column'}}>

      <div className="card">
        <div style={{padding: '10px'}}>
        <h1> {this.state.user.first_name} {this.state.user.last_name} </h1>
        <p> {this.state.user.uid} </p>

        <br></br>
        <h3> My Claimed Pages </h3>
          </div>
        <ul style={{listStyleType: 'none', margin: 0}}>
          {this.state.pages.map((carrier) => <li style={{margin:0}}><SearchResultCard carrier={carrier}/></li>)}
          {this.state.pages.length === 0 && ("No pages were claimed. You can claim a page to make edits and manage your customer.")}
        </ul>

      </div>

      <div className="card">
        <div style={{padding: '10px'}}>
        <h3> My Favorites </h3>
          </div>
        <ul style={{listStyleType: 'none', margin: 0}}>
          {this.state.favorite_safer_pages.map((carrier) => <li style={{margin:0}}><SearchResultCard carrier={carrier}/></li>)}
          {this.state.favorite_safer_pages.length === 0 && ("You don't have any favorite pages. You can add favorites by clicking the star icon on the top right of a carrier page.")}
        </ul>

      </div>


      <div className="card">
        <div style={{padding: '10px'}}>
          <Link to="/"> <Button variant="contained" onClick={this.props.logout}> logout </Button> </Link>
        </div>
      </div>
      </div>

    )
  }

}

export default Profile;
