import React from 'react';
import ReactDOM from 'react-dom';
import mapboxgl from 'mapbox-gl';
import './TopBar.css';
import MenuDropDown from './components/MenuDropDown';
import MenuDropDownItem from './components/MenuDropDownItem';

const CLASSIFICATIONS = ["Auth. For Hire", "Exempt For Hire", "Private(Property)", "Priv. Pass. (Business)", "Priv. Pass.(Non-business)", "Migrant", "U.S. Mail", "Fed. Gov't", "State Gov't", "Local Gov't", "Indian Nation"]
const OPERATION = ["Interstate", "Intrastate Only (HM)", "Intrastate Only (Non-HM)"]
const CARGO = ["General Freight", "Household Goods", "Metal: sheets, coils, rolls", "Motor Vehicles", "Drive/Tow away", "Logs, Poles, Beams, Lumber", "Building Materials", "Mobile Homes", "Machinery, Large Objects", "Fresh Produce", "Liquids/Gases", "Intermodal Cont.", "Passengers", "Oilfield Equipment", "Livestock", "Grain, Feed, Hay", "Coal/Coke", "Meat", "Garbage/Refuse", "US Mail", "Chemicals", "Commodities Dry Bulk", "Refrigerated Food", "Beverages", "Paper Products", "Utilities", "Agricultural/Farm Supplies", "Construction", "Water Well", "GRAVEL PROD."]

// <MenuDropDownItem
//   onUpdateFilters={this.props.onUpdateFilters}
//   filters={this.props.filters}
//   inputType="text"
//   itemName={"mc_mx_number"}
//   filterType="id_numbers"
// />


class TopBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openDropDown: null
    }
  }

  componentDidMount() {
    this.props.searchArea()
  }


  toggleDropDown = (dropDown) => {
    this.setState((state, props) => {
      return {openDropDown: dropDown}
    })
  }

  render() {
    return (
      <div id="filters" className="scrollable" style={{    direction:'ltr', marginLeft: '10px', maxWidth: '260px'}}>
        <p className="big-text" style={{marginTop: '25px', marginBottom: '0px'}}> Filters </p>

        <MenuDropDown
          noDropDown="true"
          buttonName="Look up Carrier"
          menuItems={<ul>
            <MenuDropDownItem
              onUpdateFilters={this.props.onUpdateFilters}
              filters={this.props.filters}
              inputType="text"
              itemName={"dot_number"}
              filterType="id_numbers"
            />

            <MenuDropDownItem
              onUpdateFilters={this.props.onUpdateFilters}
              filters={this.props.filters}
              inputType="text"
              itemName={"name"}
              filterType="id_numbers"
            />

          </ul>}
        />


        <MenuDropDown
          buttonName="Operation Classification"
          toggleDropDown={this.toggleDropDown}
          openDropDown={this.state.openDropDown == "Operation Classification"}
          menuItems={<ul className="ul-margin">
            {(this.state.openDropDown == "Operation Classification" ? CLASSIFICATIONS : CLASSIFICATIONS.slice(0,3) ).map((classification) => <MenuDropDownItem
              onUpdateFilters={this.props.onUpdateFilters}
              filters={this.props.filters}
              itemName={classification}
              filterType="operation_classification"
              />)}
             </ul>}
        />

        <MenuDropDown
          buttonName="Carrier Operation"
          toggleDropDown={this.toggleDropDown}
          openDropDown={this.state.openDropDown == "Carrier Operation"}
          menuItems={<ul className="ul-margin">
            {(this.state.openDropDown == "Carrier Operation" ? OPERATION : OPERATION.slice(0,3) ).map((operation) => <MenuDropDownItem
              onUpdateFilters={this.props.onUpdateFilters}
              filters={this.props.filters}
              itemName={operation}
              filterType="carrier_operation"
              />)}
             </ul>}
        />

        <MenuDropDown
          buttonName="Cargo Carried"
          toggleDropDown={this.toggleDropDown}
          openDropDown={this.state.openDropDown == "Cargo Carried"}
          menuItems={<ul className="ul-margin">
            {
              (this.state.openDropDown == "Cargo Carried" ? CARGO : CARGO.slice(0,3) ).map((cargo) => <MenuDropDownItem
              onUpdateFilters={this.props.onUpdateFilters}
              filters={this.props.filters}
              itemName={cargo}
              filterType="cargo_carried"
              />)}
             </ul>}
        />

      </div>
    )
  }
}

export default TopBar;
