import React, { Component } from 'react';
import selectedStar from '../assets/Review-star-selected.png';
import unselectedStar from '../assets/Review-star-unselected.png';
import './StarRating.css';

class StarRating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentRating: this.props.currentRating,
      stars: [false,false,false,false,false]
    };
  }

  hoverHandler = (n) => {
    var selectedStars = Array(n).fill(n).map((a) => true)
    var unselectedStars = Array(5-n).fill(5-n).map((a) => false)
    
    this.setState(() => {
      return {stars: selectedStars.concat(unselectedStars)}
    })
  };

  setRating = ev => {

  };

  starClickHandler = (n) => {
    this.setState({ rating: n }); // set state so the rating stays highlighted
    if(this.props.onClick){
      this.props.onClick(n); // emit the event up to the parent
    }
  };

  render() {
    return (
      <div
        data-rating={this.state.currentRating}
        onMouseOut={this.setRating}
      >
        {this.state.stars.map((n, i) => {
          return (
            <img
              className="star"
              key={i+1}
              data-value={i+1}
              onMouseOver={() => {this.hoverHandler(i+1)}}
              onClick={() => {this.starClickHandler(i+1)}}
              src={n ? selectedStar : unselectedStar}
            ></img>
          );
        })}
        <span style={{verticalAlign: 'super'}}> Select your rating </span>

      </div>
    );
  }
}

export default StarRating;
