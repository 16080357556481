import React from 'react';
import ReactDOM from 'react-dom';
import mapboxgl from 'mapbox-gl';
import './index.css';
import SearchResultCard from './SearchResultCard';

import './SearchResults.css';

import App from './App';
import * as serviceWorker from './serviceWorker';

mapboxgl.accessToken = 'pk.eyJ1IjoibmFpbHVqMjciLCJhIjoiY2tleWVucDAxMGRxYjJybDU1c252YzFwayJ9.XMPMTxmObzFBrKnQTaGNYw';

class SearchResults extends React.Component {

  calculatePages(carriers_count) {
    const number_of_pages = Math.ceil(carriers_count / 5.0)
    return number_of_pages;
  }

  render() {
    var maxPages = this.calculatePages(this.props.carriers_count);
    return (
      <div className="scrollable-main" style={{marginRight: '3px', width: '100%'}}>
        <p className="big-text"> All Results ({this.props.carriers_count}) </p>
        {this.props.carriers.length !== 0 && (
        <p>
          <span onClick={() => this.props.onPageChange(this.props.page - 1 <= 1 ? 1 : this.props.page - 1 )}>
          <u className="pointer">Previous</u> </span> {this.props.page} / {maxPages}
          <span onClick={() => this.props.onPageChange(this.props.page + 1 >= maxPages ? maxPages : this.props.page + 1 )}> <u className="pointer">Next</u> </span>
        </p>
      )}
        <ul style={{listStyleType: 'none', margin: 0}}>
          {this.props.carriers.map((carrier) => <li style={{margin:0}}><SearchResultCard carrier={carrier}/></li>)}
          {this.props.carriers.length === 0 && (<p>No results were found. You can explore a different area of the map or remove filters on desktop.<br></br><br></br>
            <b>If you believe we missed your DOT number</b>, email it to us at <a href="mailto:support@carriesfind.com"> support@carriesfind.com</a>.</p>)}
        </ul>
        <div>
        {this.props.carriers.length !== 0 && (
        <p>
          <span onClick={() => this.props.onPageChange(this.props.page - 1 <= 1 ? 1 : this.props.page - 1 )}>
          <u className="pointer">Previous</u> </span> {this.props.page} / {maxPages}
          <span onClick={() => this.props.onPageChange(this.props.page + 1 >= maxPages ? maxPages : this.props.page + 1 )}> <u className="pointer">Next</u> </span>
        </p>
      )}
        </div>
      </div>
    )
  }
}

export default SearchResults;
