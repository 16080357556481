/* global woopra */
import React from 'react';
import ReactDOM from 'react-dom';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";

import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import './SignupDialog.css';
import {API_HOST_NAME} from './Environment.js';
import StarRating from './components/StarRating';
import {isMobile} from 'react-device-detect';


class WriteAReviewDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stars: null,
      comment: null,
    }
  }

  componentDidMount() {
  }

  submitReview = () => {
    const review = {review: {
      stars: this.state.stars,
      comment: this.state.comment
      }
    }
    if (!review.review.stars || !review.review.comment) {
      this.setState({error: "Please select stars, and enter comment"})
      return
    } else {
      this.setState({error: null})
    }

    fetch(`${API_HOST_NAME()}/carriers/${this.props.dot_number}/reviews`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json',
      'uid': localStorage.getItem('uid'),
      'access-token': localStorage.getItem('access-token'),
      'expiry': localStorage.getItem('expiry'),
      'client': localStorage.getItem('client')
    },
      body: JSON.stringify(review)
    })
      .then(response => {
        response.json()})
      .then(json => {
        this.props.closeDialog();
        this.props.reloadCarrierData(this.props.dot_number, this.props.slug, 0)
        });
        woopra.track("review", {
          review: `dot_number_${this.props.dot_number}`
        });
  }


  handleChange = (event) => {
    const comment = event.target.value;
    this.setState(() => { return { comment: comment } });
  };

  render() {
    return (
        <Dialog
          fullScreen={isMobile}
          maxWidth="md"
          open={this.props.openDialog}
          onClose={this.props.closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{padding: 0}}>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p className="medium-text" style={{marginBottom: '50px'}}> Write a review for {this.props.carrier_name}.</p>
              <p style={{color: 'red'}}> {this.state.error} </p>
              <StarRating onClick={(n) => this.setState(() => {return {stars: n}})}/>
              <TextField
              style={{width: '100%', marginTop: '20px'}}
              className="writeReview"
              multiline
              rows='7'
              placeholder="leave an anonymous review here"
              value={this.state.comment} onChange={this.handleChange} />
              <br></br>
              {!isMobile && (<Button style={{float: 'right', marginRight: '30px', marginTop: '30px', color: 'white', backgroundColor: '#00A2AA'}} variant="filled" onClick={this.submitReview}>Post Review</Button>)}
            </DialogContentText>
            { isMobile && (
              <DialogActions>
                <Button onClick={() => {
                  this.setState(() => {
                    return {error: null}
                  });
                  this.props.closeDialog()
                }} color="primary">
                Cancel
                </Button>
                <Button style={{color: 'white', backgroundColor: '#00A2AA'}} variant="filled" onClick={this.submitReview}>Post Review</Button>
              </DialogActions>
            )}
          </DialogContent>
        </Dialog>
      );
  }
}

export default WriteAReviewDialog;
