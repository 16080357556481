import React from 'react';
import ReactDOM from 'react-dom';
import mapboxgl from 'mapbox-gl';
import './MenuDropDown.css'


class MenuDropDownItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      name: null,
    }
  }

  toggleDropDown = () => {
    this.setState((state, props) => {
      return {dropdown: !this.state.dropdown}
    })
  }

  keyPress = (e) => {
     if(e.keyCode == 13){
        console.log('value', e.target.value);
        if (this.props.itemName === "dot_number") {
          this.props.onUpdateFilters("id_numbers", "name", null, false);
        }
        this.props.onUpdateFilters(this.props.filterType, this.props.itemName, this.state.name);
     }
  }

  render() {
    if (this.props.inputType === "text") {
      return (
        <li style={{marginBottom: '10px'}}>
          <label> by {this.props.itemName.replace("_", " ")}</label>
          <input
            type="text"
            className={this.props.itemName}
            style={{fontSize: '20px'}}
            value={this.state.name}
            onKeyDown={this.keyPress}
            placeholder={this.props.itemName.replace("_", " ")}
            onChange={(e) => {

              const val = e.target.value;
              if (!val || val === "") {
                this.props.onUpdateFilters(this.props.filterType, this.props.itemName, null, false);
              }
              this.setState({name: val})
            }}
          />
          <p style={{margin:0, padding: 0, fontSize: '12px'}}>
          press enter when done</p>
        </li>
      );
    } else {
      return (<li style={{paddingBottom: "10px"}}>
      <label className="reg-text" >
      {
        this.props.filters[this.props.filterType][this.props.itemName] ?
        <input
          type={'checkbox' }
          value={this.props.itemName}
          checked
          onChange={(e) => {
            const val = e.target.checked;
            this.props.onUpdateFilters("id_numbers", "name", null, false);
            this.props.onUpdateFilters("id_numbers", "dot_number", null, false);

            this.props.onUpdateFilters(this.props.filterType, this.props.itemName, val);
          }}
          /> :
          <input
            type={this.props.inputType || 'checkbox' }
            value={this.props.itemName}
            onChange={(e) => {

              const val = e.target.checked;
              this.props.onUpdateFilters("id_numbers", "name", null, false);
              this.props.onUpdateFilters("id_numbers", "dot_number", null, false);
              this.props.onUpdateFilters(this.props.filterType, this.props.itemName, val);
            }}
          />
      }

          {this.props.itemName}</label>
      </li>);
    }
  }
}

export default MenuDropDownItem;
