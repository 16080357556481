import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from "react-router-dom";

import SignupDialog from './SignupDialog';
import EditCarrierInfoDialog from './EditCarrierInfoDialog';
import './SearchResultCard.css';
import truck from './assets/truck.png';
import { useParams } from "react-router";

import StarRating from './components/StarRating';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import selectedStar from './assets/Review-star-selected.png';
import unselectedStar from './assets/Review-star-unselected.png';


class Stars extends React.Component {

  render() {
    
    var numberOfStars = this.props.numberOfStars || 3;
    var numberOfUnselected = 5 - numberOfStars;

    return (
      <>
      {Array.apply(null, Array(numberOfStars)).map((val) => <img style={{width: '30px', height: '30px'}} src={selectedStar}></img>)}
      {Array.apply(null, Array(numberOfUnselected)).map((val) => <img style={{width: '30px',height: '30px'}} src={unselectedStar}></img>)}
      </>
    )
  }
}

export default Stars;
