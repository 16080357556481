/* global woopra */

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import SearchResults from './SearchResults';
import TopBar from './TopBar';
import ReactMapGL, {Marker} from 'react-map-gl';
import Geocoder from 'react-mapbox-gl-geocoder';
import locationPin from './assets/Location-pin-1-selected.png'
import MenuDropDown from './components/MenuDropDown';
import MenuDropDownItem from './components/MenuDropDownItem';
import {Helmet} from "react-helmet";
import { withRouter } from "react-router-dom";


const mapStyle = {
    width: '100%',
    height: '92vh',
}
const params = {
    country: "us",
      zoom: 10,
}

const mapboxApiKey ='pk.eyJ1IjoibmFpbHVqMjciLCJhIjoiY2tleWVucDAxMGRxYjJybDU1c252YzFwayJ9.XMPMTxmObzFBrKnQTaGNYw';

class SearchPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      longitude: this.props.longitude,
      latitude: this.props.latitude,
      zoom: this.props.zoom,
      filters: {
        cargo_carried: {},
        operation_classification: {},
        carrier_operation: {},
        id_numbers: {
          dot_number: null,
          mc_mx_number: null
        },
      },
      carriers: [],
      carriers_count: null,
      markers: [],
    }
  }


  componentDidUpdate(prevProps) {
    var dot = this.props.filters?.id_numbers?.dot_number
    if (dot) {
      var element = document.getElementsByClassName("dot_number")[0];
      if (element && element.value !== dot) {
        element.value = dot
        window.scroll(0,0);
      }
    }
  }


  render() {

    return (
      <div>
          <div className="main">
          <Helmet>
                <meta charSet="utf-8" />
                <title>Carrie's Find</title>
                <meta
                  name="description"
                  content="Carrie's Find lets you search and review carriers of any kind."
                />
          </Helmet>

          <TopBar searchArea={this.props.searchArea}
           onUpdateFilters={this.props.onUpdateFilters}
           filters={this.props.filters}
           />

           <p class="mobileWarning"> You can only search by <b>DOT number</b> or <b>name</b> on mobile. All other filters are available on laptop.</p>

           <div className="mobileSearchBar" style={{display: 'flex', flexDirection: 'row', alignItems: 'start', marginTop: '10px'}}>
             <input
               type="text"
               style={{fontSize: '20px', height: '37px', width: '100%'}}
               placeholder={"DOT or Name"}
               onChange={(e) => {
                 const val = e.target.value;
                 this.setState({search_input: val})
               }}
             />
           <button className="dot-research-area-button" style={{verticalAlign: 'bottom'}} onClick={() => {
             var search_by = "dot_number"
             if (isNaN(this.state.search_input)){
               search_by = "name"
               this.props.onUpdateFilters("id_numbers", "dot_number", null, false)
             } else {
               this.props.onUpdateFilters("id_numbers", "name", null, false)
             }
            this.props.onUpdateFilters("id_numbers", search_by, this.state.search_input)
            }}>Search</button>
           </div>

          <SearchResults
            carriers={this.props.carriers}
            carriers_count={this.props.carriers_count}
            onPageChange={this.props.onPageChange}
            page={this.props.page}
          />
          <div className="cf-mapbox-map">

       
          <ReactMapGL
              className="cf-mapbox-map"
              mapboxApiAccessToken={mapboxApiKey}
              mapStyle="mapbox://styles/mapbox/streets-v11"
              {...this.props.viewport}
              {...mapStyle}
              width="100%"
              onViewportChange={(viewport) => {
                this.props.setViewport(viewport)
              }}
          >
   <Geocoder
            mapboxApiAccessToken={mapboxApiKey}
            onSelected={this.props.onSelected}
            updateInputOnSelect={true}
            viewport={this.props.viewport}
            hideOnSelect={true}
            queryParams={this.props.params}
            className="cf-geocoder"
            initialInputValue={this.props.initialInputValue}
          />

          { this.props.showSearchAreaButton && (<button onClick={this.props.searchArea} className="research-area-button">Search Area</button>)}
          {
            this.props.carriers.map((carrier) =>

                ( carrier.address && carrier.address.longitude &&   <Marker className="cf-mapbox-map" key={carrier.id} longitude={carrier.address.longitude} latitude={carrier.address.latitude} >
                    <img width="35px" src={locationPin} />
                  </Marker> )
            )
          }
          </ReactMapGL>
          </div>

          </div>
      </div>
    )
  }
}

export default withRouter(SearchPage);
