/* global woopra*/
import React from 'react';
import ReactDOM from 'react-dom';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";

import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import {API_HOST_NAME} from './Environment.js';
import {isMobile} from 'react-device-detect';
import {Helmet} from "react-helmet";



class RecordInactive extends React.Component {

  constructor(props) {
    super(props);
  }


  render() {
    return (
      <>
      <Helmet>
        <meta name="robots" content="noindex"/>
      </Helmet>

      <div id="carrier-page" style={{display: 'flex'}}>
      <div className="card first-card" >
      <div className="backgroundImage">

        <div className="bottomCard">
          <h1 style={{color: 'white'}}> {this.props.dot_number} is not available. </h1>
          </div>
        </div>
          <div style={{marginLeft: '30px'}}>
            <h2> About</h2>
            <p> This carrier is currently not available from the safer company snapshot.
            If this is your DOT number, please fix and your page will be available again.
              No reviews, or direct messages will be lost.
            </p>

            <br></br>
          </div>

        </div>
      </div>
      </>
);
  }
}

export default RecordInactive;
