/* global woopra*/
import React from 'react';
import ReactDOM from 'react-dom';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";

import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import {API_HOST_NAME} from './Environment.js';
import {isMobile} from 'react-device-detect';


class ClaimDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_send_verification: true,
      show_congratulations: false,
    }
  }

  toggleSignin = () => {
    this.setState(() => {return {showSignin: !this.state.showSignin}})
  }

  componentDidMount() {
  }

  handleSubmit = () => {
    const data = {
      last4_phone_number: this.state.last4_phone_number
    }

    fetch(`${API_HOST_NAME()}/carriers/${this.props.dot_number}/send_phone_number_verification`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json',
      'uid': localStorage.getItem('uid'),
      'access-token': localStorage.getItem('access-token'),
      'expiry': localStorage.getItem('expiry'),
      'client': localStorage.getItem('client')
    },
      body: JSON.stringify(data)
    })
      .then(response => {
        return response.json()})
      .then(json => {
        if (json.success) {
          this.setState({show_send_verification: false, error: null})
        } else {
          this.setState({error: json.error})
        }
      });

  }



  handleSubmitToken = () => {
    const data = {
      token: this.state.token
    }

    fetch(`${API_HOST_NAME()}/carriers/${this.props.dot_number}/claim`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json',
      'uid': localStorage.getItem('uid'),
      'access-token': localStorage.getItem('access-token'),
      'expiry': localStorage.getItem('expiry'),
      'client': localStorage.getItem('client')
    },
      body: JSON.stringify(data)
    })
      .then(response => {
        return response.json()})
      .then(json => {
        if (json.success) {
          this.setState({show_congratulations: true});          
          woopra.track("claim", {
            claim: `dot_number_${this.props.dot_number}`
          });                  
        } else {
          this.setState({error: "Token did not match what we sent you."})
        }
      });
  }


  render() {

    var dialog_content = "";
  
    if (this.state.show_congratulations) {
        dialog_content = <DialogContent>
          <p> Congratulations! You have claimed your first Page. You can now edit receive messages from customers and more.</p>

          <Button style={{width: '320px',backgroundColor: '#1A71D8', color: 'white', fontWeight: 600, margin: '10px'}}  onClick={() => {this.props.closeDialog(); this.props.reloadCarrierData(this.props.dot_number, this.props.slug, 0);
}}>Close</Button>
        </DialogContent>
    } else {
      if (this.state.show_send_verification) {
        dialog_content =  <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <p style={{color: 'black'}}>{this.state.error} </p>
          <form style={{height: '300px'}}>
          
            <div>
              <p style={{color: 'white'}}> Phone Number: {this.props.phone_number?.slice(0, -4)}**** </p>
              <TextField  id="last4"
              variant="filled"
              className="textfield"

                          style={{width: '250px', marginBottom: '40px'}}
                          label="Last 4 digits of phone number"
                          onChange={(e) => {
                            const value = e.target.value;
                            this.setState(() => {return {last4_phone_number: value}})
                          }} />
            </div>
            <div style={{color: 'white', textAlign: 'left', marginBottom: '20px'}}>
              By Sumitting you agree that we may call you on this phone to verify that you are the owner of {this.props.legal_name}
            </div>
            <div>
            { !isMobile && (<Button variant="contained" style={{width: '320px',backgroundColor: '#1A71D8', color: 'white', fontWeight: 600}} onClick={this.handleSubmit} variant="contained">Verify Phone Number</Button>)}
            </div>
        </form>
        </DialogContentText>
        { isMobile && (
          <DialogActions>
            <Button onClick={() => {
              this.setState(() => {
                return {error: null}
              });
              this.props.closeDialog()
            }} color="primary">
            Cancel
            </Button>
            <Button variant="contained" style={{width: '320px',backgroundColor: '#1A71D8', color: 'white', fontWeight: 600}} onClick={this.handleSubmit} >Claim Page</Button>
          </DialogActions>

        )}
        </DialogContent>
      } else {
        dialog_content = <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <p style={{color: 'red'}}>{this.state.error} </p>
          <form style={{height: '300px'}}>
            <p style={{color: 'black'}}> You are receiving an automated phone call with a code. Please enter code here:
             </p>
            <div>
              <TextField  id="token"
              variant="filled"
              className="textfield"

                          style={{width: '350px', marginBottom: '40px'}}
                          label="One Time Code"
                          onChange={(e) => {
                            const value = e.target.value;
                            this.setState(() => {return {token: value}})
                          }} />
            </div>              
            <div>
            { !isMobile && (<Button onClick={this.handleSubmitToken} style={{width: '320px',backgroundColor: '#1A71D8', color: 'white', fontWeight: 600}}  variant="contained">Claim Page</Button>)}
            </div>
        </form>
        </DialogContentText>
        { isMobile && (
          <DialogActions>
            <Button onClick={() => {
              this.setState(() => {
                return {error: null}
              });
              this.props.closeDialog()
            }} color="primary">
            Cancel
            </Button>
            <Button onClick={this.handleSubmit} style={{width: '320px',backgroundColor: '#1A71D8', color: 'white', fontWeight: 600}} >Claim Page</Button>
          </DialogActions>

        )}
        </DialogContent>
      }
    }

    


    if (this.props.showClaimDialog) {
      return (
        <Dialog
          fullScreen={isMobile}
          maxWidth="sm"
          open={this.props.openDialog}
          onClose={this.props.closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{className: 'signupDialog'}}
        >
          <DialogTitle id="alert-dialog-title">
          <p className="medium-text" style={{fontFamily: 'Fira Sans', fontWeight: 600, fontStyle:'normal', textAlign: 'center', color: 'white', marginBottom: '10px', marginTop: '30px'}}>           Verify Your Phone Number
          </p>
          </DialogTitle>
          
          <div style={{textAlign: 'center'}}>
          {dialog_content}
          </div>
          
        </Dialog>
      );
    } else {
      return null
    }


  }
}

export default ClaimDialog;
