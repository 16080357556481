import React from 'react';
import mapArea from './assets/map-area.png'
import searchArea from './assets/search-area.png'
import filtersArea from './assets/filters-area.png'
import claimWebpage from './assets/claimwebpage.png'


const HowToPage = (props) => (
  <div className="medium-text" style={{maxWidth: '750px', margin: '80px auto'}}>
      <h1> FAQ </h1>
      <h2>How to change location for my search</h2>

      <p>
      Find the screenshot here of the map area located on the homepage. You can click and drag on the map to change location. You can trigger a new search in that area by clicking the search area button.
      If you wish to navigate to a complete different area, use the search box at the top to enter a location.
      <br></br>
      <figure>
        <img width="300px" src={mapArea} alt="map area" border="5px grey"/>
        <figcaption><b className="small-text">The map area on the right of the main screen</b></figcaption>
      </figure>
      </p>

      <h2>How to filter out my results</h2>
      <p>
      On the left pane of the homepage, you can search by DOT number or by name. Just enter the dot number and type enter.

      You can also filter by different types of licenses. For instance, you can search for a carrier "Authorized For Hire", who's transported
      "Building Materials" equipment. Just use the checkboxes to select your filter.
      <br></br>
      <figure>
      <img width="300px" src={filtersArea} alt="filters area" border="5px grey"/>
        <figcaption><b className="small-text">The filter area on the left of the main screen</b></figcaption>
      </figure>

      </p>

      <h2> Navigate through your search </h2>
      <p>
        Once your area and filters are set, you can scroll through your results. You can see more details by clicking "Show More".
        <br></br>
        <figure>
          <img width="300px" src={searchArea} alt="search area" border="5px grey"/>
          <figcaption><b className="small-text">The search area in the middle of the screen</b></figcaption>
        </figure>
      </p>

      <h2> Claim a webpage </h2>

      <p>
        If you own one of the business on our site you can claim that page. Claiming a page
        allows you to edit your about section, and receiving inquiries (messages) via our platform. Here is how you can claim your page:
        first find your dot number by going in any carrier page and typing it in the search box. Then click the "Claim your webpage now" link.
        <br></br>
        <figure>
          <img width="300px" src={claimWebpage} alt="Claim Webpage area" border="5px grey"/>
          <figcaption><b className="small-text">Under the map on a carrier page</b></figcaption>
        </figure>
      </p>
      
      <h2>Contact Us</h2>

      <p>
        Please contact us at support@carriesfind.com, and we will do our best to meet your needs.
      </p>
</div>
);


export default HowToPage;
