/* global woopra */
import React from 'react';
import ReactDOM from 'react-dom';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";

import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import './SignupDialog.css';
import {API_HOST_NAME} from './Environment.js';
import StarRating from './components/StarRating';
import {isMobile} from 'react-device-detect';
import ReCAPTCHA from "react-google-recaptcha";


class SendMessageDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      body: null,
      email: null
    }
  }

  submitMessage = () => {
    const message = {message: {
        body: this.state.body,
        email: this.state.email
      },
      recaptcha: this.state.recaptcha
    }

    if (!this.state.recaptcha) {
      return
    }

    fetch(`${API_HOST_NAME()}/carriers/${this.props.dot_number}/message`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json',
      'uid': localStorage.getItem('uid'),
      'access-token': localStorage.getItem('access-token'),
      'expiry': localStorage.getItem('expiry'),
      'client': localStorage.getItem('client')
    },
      body: JSON.stringify(message),
    })
      .then(response => {
        return response.json()})
      .then(json => {
        if (json.error_message) {
           return
        }

        this.props.closeDialog();
        this.props.reloadCarrierData(this.props.dot_number, this.props.slug, 0)
        });
        woopra.track("message", {
          dot: `dot_number_${this.props.dot_number}`
        });
  }


  recaptchaOnChange = (value) => {
    this.setState({recaptcha: value})
  }

  handleChange = (event) => {
    const message = event.target.value;
    this.setState(() => { return { body: message } });
  };

  handleChangeEmail = (event) => {
    const email = event.target.value;
    this.setState(() => { return { email: email } });
  };




  render() {
    return (
        <Dialog
          fullScreen={isMobile}
          maxWidth="md"
          open={this.props.openDialog}
          onClose={this.props.closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{padding: 0, margin: 0}}>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p className="medium-text" style={{marginBottom: '20px'}}>Send an inquiry to {this.props.carrier_name}.</p>
              <p> This is a private message to the business. <br></br>If you wish to write a <b>public review</b>, exit out of this popup and leave a review.
              </p>
              <TextField
              style={{width: '100%', marginTop: '20px'}}
              multiline
              rows='3'
              label="Message (Required)"
              variant="filled"
              placeholder="Write your message here..."
              value={this.state.body} onChange={this.handleChange} />

              <TextField
              label="Email (Optional)"
              variant="filled"
              placeholder="Use this field to leave contact information"
              style={{width: '100%', marginTop: '25px', marginBottom: '10px'}}
              value={this.state.email} onChange={this.handleChangeEmail} />
              <br></br>
              <br></br>

              <ReCAPTCHA
                sitekey="6LeUrRsbAAAAAB1qhFI94LbuUTcMLIgtaV-1oKEx"
                onChange={this.recaptchaOnChange}
              />
              <br></br>
              {!isMobile && (<Button style={{float: 'right', marginRight: '30px', marginTop: '30px', marginBottom: '30px', color: 'white', backgroundColor: '#00A2AA'}} variant="filled" onClick={this.submitMessage}>Send Message</Button>)}
            </DialogContentText>
            { isMobile && (
              <DialogActions>
                <Button onClick={() => {
                  this.setState(() => {
                    return {error: null}
                  });
                  this.props.closeDialog()
                }} color="primary">
                Cancel
                </Button>
                <Button style={{color: 'white', backgroundColor: '#00A2AA'}} variant="filled" onClick={this.submitMessage}>Send Message</Button>
              </DialogActions>
            )}

          </DialogContent>
        </Dialog>
      );
  }
}

export default SendMessageDialog;
