import React from 'react';
import ReactDOM from 'react-dom';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";

import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import {API_HOST_NAME} from './Environment.js';

class EditCarrierInfoDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditInfo: false,
    }
  }

  toggleEditDialog = () => {
    this.setState(() => {return {showEditInfo: !this.state.showEditInfo}})
  }

  componentDidMount() {
  }

  updateInfo = () => {

    const data = {
      description: this.state.description
    }

    fetch(`${API_HOST_NAME()}/carriers/${this.props.dot_number}/`, {
      method: 'PUT',
      headers: {'Content-Type': 'application/json',
      'uid': localStorage.getItem('uid'),
      'access-token': localStorage.getItem('access-token'),
      'expiry': localStorage.getItem('expiry'),
      'client': localStorage.getItem('client')
    },
      body: JSON.stringify(data)
    })
      .then(response => {
        response.json()})
      .then(json => {
        this.setState( (state, props) => {
          return json
        });
        this.props.closeDialog();
        this.props.reloadCarrierData(this.props.dot_number, this.props.slug, 0);
      });

  }

  render() {
    if (this.props.showEditInfo) {
        return (
            <Dialog
              maxWidth="md"
              open={this.props.openDialog}
              onClose={this.props.closeDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{style:  {width: '500px', height: '375px'}}}
            >
              <DialogTitle id="alert-dialog-title">
                <span> Submit new text for the About Section </span>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <form style={{textAlign: 'center'}}>

                    <div>
                      <TextField  id="description" label="About"
                                  multiline
                                  rows="8"
                                  style={{width: '100%'}}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    this.setState(() => {return {description: value}})
                                  }} />
                    </div>

                    <div>
                        <Button style={{float: 'right', marginTop: '50px'}} onClick={this.updateInfo} variant="contained">Update</Button>
                    </div>
                </form>
                </DialogContentText>
              </DialogContent>
            </Dialog>
          );
    } else {
      return null;
    }
  }
}

export default EditCarrierInfoDialog;
