
import React from 'react';

const PrivacyPolicy = (props) => (
  <div style={{margin: 'auto', width: '650px'}}>
    <h1>Privacy Policy </h1>
    <p>
      Last Updated on Feb 01, 2021. This Privacy Policy is effective January 1, 2021 for all users.
    </p>
    <p> Carrie's Find is a product of Easy Tech Services, LLC. We may refer to us interchanganbly between our product name and company name </p>
    <p>
    Carrie's Find mission is to help shipper and drivers find carriers of ANY kind. In order to do so, we do collect some information, and also collect user-generated content such as reviews.
    </p>

    <p>
    <p>
    This Privacy Policy describes our policies on the collection, use, and disclosure of
    information about you in connection with your use of our services, including those offered
    through our websites, communications (e.g., emails, phone calls, and texts), and mobile
    applications (collectively, the “Service”). The terms “we”, “us”, and "Carrie's Find", or "CF" refer to: (i) Easy Tech Services, LLC, an LLC with its headquarters in Sunny Isles Beach, Florida.
    </p>

    <p>
    When you use the Service, you consent to our collection, use, and disclosure of information about you as described
    in this Privacy Policy. We may translate this Privacy Policy into other languages for your convenience.
    Nevertheless, the English version governs your relationship with us, and any
    inconsistencies among the different versions will be resolved in favor of the English
    version available here.
    </p>

  <p>
  Please note:
  </p>
<p>
    <b>Account Information</b>: When you create a CF account, we store and use the information
    you provide during that process, such as the first and last name you enter,
    email address, zip code, physical address,
    and any other information you may provide during the account creation process,
    such as a gender, phone number, or birth date.
    We may publicly display the first name and last initial that you provide, as well as any photo or other content you submit through the account creation process, as part of your account profile. We also store and use any preferences you provide to personalize your user experience, including dining and activity preferences. You can later modify some of the account information you provide through your account settings. If you believe that someone has created an unauthorized account using your personal information, you can request its removal by flagging it.
</p>
    <b>Public Content</b>: Your contributions to the Service are intended for public consumption
    and are therefore viewable by the public, including your photos, ratings, reviews, tips,
    lists, Collections, compliments, Ask the Community posts,
    CF Talk posts, and edits to business page information.
    Your account profile (e.g., first name, last initial, city, neighborhood, the month and year you
    created your CF account, profile photos and list of friends on CF)
    is also intended for public consumption, as is some of your other
    activity through the Service, like how you vote on other people’s
    contributions (e.g., useful, funny, cool, helpful, or not helpful),
    which contributions you like, where you check-in (including when
    friends on CF tag you in their own check-ins), which contributions or users
    you follow, and which businesses you follow or bookmark. You can limit the
    public nature of some of these activities through your account settings.

<p>
    <b>Contacts</b>: You can invite others to join or become your friend on CF by
    providing us with their contact information, or by allowing us to access
    contacts from your computer, mobile device, or third party sites to select
    which individuals you want to invite. If you allow us to access your contacts,
    we will transmit information about your contacts to our servers long enough to
    process your invitations.
</p>

<p>
  <b>Communications</b>: When you sign up for an account or use certain features,
  you are opting to receive messages from other users,
  businesses, and CF. You can manage some of your messaging preferences
  through your account settings, but note that you cannot opt out of receiving
  certain administrative, transactional, or legal messages from CF.
  For example, if you make a reservation, order food, place yourself on a waitlist,
  or request a quote from a business through the Service, we may send you messages
  about your transaction using the contact information you provide, including through
  automated SMS text messages to your phone number. We may also track your actions in
  response to the messages you receive from us or through the Service, such as whether
  you deleted, opened, or forwarded such messages, and share that information with
  other parties to your messages (for example, to let them know if you have viewed
  their message). When a user requests a quote or similar information from multiple
  businesses through the Service, we may disclose to those businesses information about
  the status of the user’s requests, such as whether any business has responded to the request,
  the type of response provided (for example, a quote or a request for more information),
  and whether the user has responded to any business’s reply. If you exchange messages with
  others through the Service, we may store them in order to process and deliver them,
  allow you to manage them, and we may review and disclose them in connection with
  investigations related to use of the Service, as well as our efforts to improve the Service. We may not deliver messages that we believe are objectionable, such as spam messages, fraudulent solicitations, or requests to exchange reviews for compensation. If you send or receive messages through the Service via SMS text message, we may log phone numbers, phone carriers, and the date and time that the messages were processed. Carriers may charge recipients for texts that they receive. We may also store information that you provide through communications to us, including from phone calls, letters, emails and other electronic messages, or in person. If you are a representative of a business listed on CF, or use a phone number associated now or previously with such a business, we may contact you, including by phone or email, using the contact information you provide us, make publicly available, or that we have on record for your business. Our communications with you, including phone calls, may be monitored and recorded for quality purposes.
</p>

<p>
<b>Transactions</b>: If you initiate a transaction through the Service, such as a reservation or purchase, we will collect and store information you provide associated with your transaction, such as your name, phone number, address, email, and payment information, as well as any other information you provide, in order to process your transaction, send communications about the transaction to you, and facilitate future transactions. This information may be shared with third parties, and third parties may share such information with us, for the same purposes. When you submit credit card numbers and other sensitive payment information, that information is encrypted using industry standard technology. If you write reviews about businesses with which you transact through the Service, we may publicly display the fact that you transacted with those businesses in connection with such reviews. For example, if you make a dining reservation through the Service and later write a review about your experience, we may publicly display the fact that you made your reservation through the Service.
Activity: We store information about your use of the Service, such as your search activity, the pages you view, the date and time of your visits, businesses you call using our mobile applications, and reservations, purchases, or transactions you make through the Service. We also store information that your computer or mobile device may provide to us in connection with your use of the Service, such as your browser type, type of computer or mobile device, browser language, IP address, WiFi information such as SSID, mobile carrier, phone number, unique device identifier, advertising identifier, location (including geolocation, beacon based location, and GPS location), and requested and referring URLs and mobile applications. We may also receive and store your location whenever our mobile applications are running, including when running in the background, if you enable our mobile apps to access such information in the course of using the Service. You may be able to limit or disallow our use of certain location data through your device or browser settings, for example by adjusting the settings for our applications in iOS or Android privacy settings.
Different Devices: You may access the Service through different devices (e.g., your mobile phone or personal computer) and different platforms (e.g., the CF website or CF mobile apps). The information that we collect and store through those different uses may be cross-referenced and combined, and your contributions through one CF platform will typically be similarly visible and accessible through all other CF platforms.
Professional Information: If you represent a business on CF, we may collect and display information about you related to your business activities, such as a business name that may incorporate your name, or a publicly available business address that is also used as a home address. We will also collect and display information about your professional background that you choose to share with us through the Service, such as information about your professional licenses or certifications, or your professional history or specialties.
</p>


<h3>COOKIES</h3>
<p>
We, and third parties with whom we partner, may use cookies, web beacons, tags, scripts, local shared objects such as HTML5 and Flash (sometimes called “flash cookies”), advertising identifiers (including mobile identifiers such as Apple’s Identifier for Advertisers (“IDFA”) or Google’s Advertising ID (“GAID”)) and similar technology (“Cookies”) in connection with your use of the Service, third party websites, and mobile applications. Cookies may contain unique identifiers, and reside, among other places, on your computer or mobile device, in emails we send to you, and on our web pages. Cookies may transmit information about you and your use of the Service, such as your browser type, search preferences, IP address, data relating to advertisements that have been displayed to you or that you have interacted with, and the date and time of your use. Cookies may be persistent or stored only during an individual session.
</p>
<p>
The purposes for which we use Cookies in the Service include:
</p>
<p>
Processes	Intended to make the Service work in the way you expect. For example, we use a Cookie that tells us whether you have already signed up for an account.
Authentication, Security, and Compliance	Intended to prevent fraud, protect your data from unauthorized parties, and comply with legal requirements. For example, we use Cookies to determine if you are logged in.
Preferences	Intended to remember information about how you prefer the Service to behave and look. For example, we use a Cookie that tells us whether you have declined to allow us to send push notifications to your phone.
Notifications	Intended to allow or prevent notices of information or options that we think could improve your use of the Service. For example, we use a Cookie that stops us from showing you the signup notification if you have already seen it.
Advertising	Intended to make advertising more relevant to users and more valuable to advertisers. For example, we may use Cookies to serve you interest-based ads, such as ads that are displayed to you based on your visits to other websites, or to tell us if you have recently interacted with an ad.
Analytics	Intended to help us understand how visitors use the Service. For example, we use a Cookie that tells us how our search suggestions correlate to your interactions with the search page.
You can set some Cookie preferences through your device or browser settings, but doing so may affect the functionality of the Service. The method for disabling Cookies may vary by device and browser, but can usually be found in your device or browser preferences or security settings. For example, iOS and Android devices each have settings which are designed to limit forms of ad tracking. For flash cookies, you can manage your privacy settings by clicking here. Please note that changing any of these settings does not prevent the display of certain advertisements to you.
</p>


<h3>THIRD PARTIES</h3>
Third parties may receive information about you as follows:
<p>
<b>Advertisers</b>: We allow third parties to use Cookies through the Service to collect the same type of information for the same purposes as we do. In doing so, we adhere to the Digital Advertising Alliance’s Self-Regulatory Principles for Online Behavioral Advertising. Third parties may be able to associate the information they collect with other information they have about you from other sources. We do not necessarily have access to or control over the Cookies they use, but you may be able to opt out of some of their practices by visiting the following links: Network Advertising Initiative, Omniture and Digital Advertising Alliance. Please note that opting out does not prevent the display of all advertisements to you. We may also share some non-identifiable, de-identified or aggregated information from or about you, such as location information, with third parties in connection with advertising programs and data analytics. You may be able to limit our sharing of some of this information through your mobile device settings, as described in Section 2 above, or through the Service’s settings.
Content Partners: We allow third party partners to use and display some of the public content available through the Service, such as your photos, reviews, and other information listed under “Public Content” in Section 1 above.
Service Providers: We rely on third party service providers to support or provide services for us in connection with your use of the Service, such as food delivery services, communications and hosting, security and fraud prevention, technical and customer support, tracking and reporting usage of the Service, quality assurance testing, payment processing, marketing, and other functions. We share information from or about you with these third party providers so that they can perform their services or complete your requests. For example, we may share your advertising identifiers (e.g., your IDFA, GAID, or a cryptographic hash of your email address) with third party service providers that help us facilitate CF’s advertising programs and measure and report their effectiveness, including with third parties that help determine the efficacy of CF’s advertising programs by combining information they receive about you from other sources than the Service. You may be able to limit our access to some of this information through your mobile device settings, as described in Section 2 above, or through the Service’s settings. CF’s third party service providers may likewise share information with us that they obtain from or about you in connection with providing their services or completing your requests.
Aggregate or Anonymous Information: We share user information in the aggregate with third parties, such as businesses that are listed on CF and content distributors. For example, we disclose the number of users that have been exposed to or interacted with advertisements, or that we believe visited the physical location of a particular business.
Business Transfers: We share information from or about you with our parent companies, subsidiaries, joint ventures, or other companies under common control, and require them to also honor this Privacy Policy. If another company acquires CF, or all or substantially all our assets, that company will possess the same information, and will collect, use, and disclose the information only as described in this Privacy Policy.
Businesses on CF: We may share information from or about you (such as your city, and if you provide it, your age and gender), your device type, and your use of the Service (such as which businesses you bookmark or call, or if you visit a business’s URL) with businesses on CF. You may adjust your account settings to increase or decrease the amount of information we share. Keep in mind that businesses can also view your public activity and posts, and may receive information from or about you when you transact or communicate with them, through CF or otherwise, regardless of your settings (see Section 1 above). Additionally, if you make a phone call to a business through or in connection with your use of the Service, we may share information about your call with the business that the business would have received had you called them directly, such as the date and time of your call and your phone number. You may be able to limit our ability to collect and share your phone number through your phone settings or phone service provider.
Investigations and Legal Disclosures: We may investigate and disclose information from or about you if we have a good faith belief that such investigation or disclosure: (a) is reasonably necessary to comply with legal or law enforcement processes, such as a search warrant, subpoena, statute, judicial proceeding, or other legal process or law enforcement request; (b) is helpful to prevent, investigate, or identify possible wrongdoing in connection with the Service; or (c) protects our rights, reputation, property, or that of our users, affiliates, or the public, such as disclosures in connection with CF’s Consumer Alerts program. If you flag or otherwise complain to us about content through the Service, we may share the substance of your complaint with the contributor of that content in order to provide an opportunity for the contributor to respond.
Links: The Service may link to third party-controlled websites, like a business’s URL. Except as set forth herein, we do not share your personal information with them, and are not responsible for their privacy practices.
Third Party Integrations: If you sign up for, or log into, CF using a third party service like Facebook or Google, or link your CF account to your account with a third party service like Facebook, Instagram or Twitter, we may receive information about you from such third party service. If you post content to a third party service through the Service, that third party service will also receive that content, which will be visible to anyone that has access to it through that third party service. Some of our web pages utilize framing techniques to serve content to you from our third party partners, while preserving the look and feel of the Service. In such cases, please note that the information you provide may be transmitted directly to the identified third party partner. If you interact with businesses through CF, they will receive whatever information you choose to share with them, for example contact information you share through direct messages to the business or your phone number if you call the business.
</p>

<p>
<h3>CONTROLLING YOUR PERSONAL DATA</h3>
Other users may be able to identify you, or associate you with your account, if you include personal information in the content you post publicly. You can reduce the risk of being personally identified by using the Service pseudonymously, though doing so could detract from the credibility of your contributions to the Service. CF users can also use the Find Friends feature to find one another based on their names or email addresses (you can limit your visibility for this feature in your account settings). Please also note that the messages you send or receive using the Service are only private to the extent that both you and the recipient(s) of your messages keep them private. For example, if you send a message to another user, that user may choose to publicly post it. We may access, review, and disclose such messages in connection with investigations related to use of the Service, as well as our efforts to improve the Service.
</p>


<h3>DATA RETENTION AND ACCOUNT TERMINATION</h3>
You can close your account by emailing us at support@carriesfind.com.
<p>
We will remove certain public posts from view and/or dissociate them from your account profile, but we may retain information about you for the purposes authorized under this Privacy Policy unless prohibited by law. For example, we may retain information to prevent, investigate, or identify possible wrongdoing in connection with the Service or to comply with legal obligations. We may also maintain residual copies of your personal information in our backup systems. Please note that businesses cannot remove their business pages, ratings, or reviews by closing their accounts.
</p>

<p>
CHILDREN
The Service is intended for general audiences and is not directed to children under 13. We do not knowingly collect personal information from children under 13. If you become aware that a child has provided us with personal information without parental consent, please contact us here. If we become aware that a child under 13 has provided us with personal information without parental consent, we take steps to remove such information and terminate the child’s account.
</p>

<p>
SECURITY
We use various safeguards to protect the personal information submitted to us, both during transmission and after we receive it. However, no method of transmission over the Internet or via mobile device, or method of electronic storage, is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
</p>

<p>
CONTACT INFORMATION
You may contact us online concerning our Privacy Policy, or write to us at the following address:
</p>
<p>
Carrie's Find, Attn: Data Privacy Manager<br/>
500 Bayview Drive, #1818,<br/>
Sunny Isles Beach, FL, 33160<br/>
</p>

<p>
For security purposes, we will take such steps as we deem necessary to confirm your identity before completing your request or sharing any personal data with you.
</p>

<h3> MODIFICATIONS TO THIS PRIVACY POLICY</h3>
We may revise this Privacy Policy from time to time. The most current version of the Privacy Policy will govern our collection, use, and disclosure of information about you and will be located here. If we make material changes to this Privacy Policy, we will notify you by email or by posting a notice on the Service prior to or on the effective date of the changes. By continuing to access or use the Service after those changes become effective, you acknowledge the revised Privacy Policy.

<h3>CALIFORNIA RESIDENTS: YOUR CALIFORNIA PRIVACY RIGHTS</h3>

<p>Under the California Consumer Privacy Act of 2018 (“CCPA”), California residents have certain rights around CF’s collection, use, and sharing of their personal information.</p>

<p> CF does not sell your personal information and will not do so in the future without providing you with notice and an opportunity to opt-out of such sale as required by law. Similarly, we do not offer financial incentives associated with our collection, use, or disclosure of your personal information.</p>

<p> CF collects various categories of personal information when you use the Service, including identifiers, commercial information, internet or other electronic network or device activity information, geolocation data, and professional information. A more detailed description of the information CF collects and how we use it is provided above in Section 1 (Information We Collect and How We Use It). Section 3 (Third Parties) describes the categories of third parties with whom we share personal information, and what information may be shared under different circumstances.</p>

<p> If you are a resident of California, you have the right to request to know what personal information has been collected about you, and to access that information. You also have the right to request deletion of your personal information, though exceptions under the CCPA may allow CF to retain and use certain personal information notwithstanding your deletion request. You can learn more about how to submit a data rights request through CF’s Support Center You may also send your request by email to dataprotection@carriesfind.com. We will not discriminate against you for exercising your rights under the CCPA. An agent may submit a request on your behalf, but you must verify that your agent is authorized to do so.</p>

<p> Separate from the CCPA, California’s Shine the Light law gives California residents the right to ask companies what personal information they share with third parties for those third parties’ direct marketing purposes. We do not disclose your personal information to third parties for the purpose of directly marketing their goods or services to you unless you request such disclosure. If you have any questions regarding this policy, or would like to change your preferences, you may contact us at the address listed above in Section 8.</p>

<h3> NEVADA RESIDENTS: YOUR NEVADA PRIVACY RIGHTS</h3>
<p> Nevada law (SB 220) requires website operators to provide a way for Nevada consumers to opt out of the sale of certain information that the website operator may collect about them. CF does not sell your personal information to third parties as defined in Nevada law, and will not do so in the future without providing you with notice and an opportunity to opt-out of such sale as required by law. If you have any questions regarding our data privacy practices or our compliance with Nevada data privacy law, please contact us at support@carriesfind.com. </p>

</p>

  </div>
);


export default PrivacyPolicy;
