import React from 'react';


const AboutPage = (props) => (
  <div className="medium-text" style={{maxWidth: '750px', margin: '80px auto'}}>
      <h1>Carrie's Find</h1>

      <h2>What we do?</h2>

      <p> Carrie's Find is on a mission to bring more transparency to all carriers.
      Today, the information needed to find new carriers is burried in many sites.
      By exposing the information on a beautiful webpage, a carrier can keep their information
      updated, and customers can find them faster. Having up-to-date information combined with a great search engine and reviews is an all star product
      for the logistics industry or any customer in need of the trucking workforce.
      </p>

      <p>If you are a carrier, we urge you to look yourself up and claim your page.
      You will be able to edit your information as you go,
      and you can treat this page like free advertising targeting customers trying to find you.
      </p>

      <p>Reviews are necessary to make the experience better for someone looking for your services.
      We understand that not all reviews will be accurate and we are always willing to help resolve disputes.
      Reviews today are anonymous, and can be left by anyone including your own drivers: getting an understanding
      of how your operation run is totally acceptable. Reviews are not required to be from customers.
      </p>

      <h2>Who we are?</h2>

      <p> Carrie is real! She is helping us understand how best find new carriers.
      The team here is composed of logistics experts that have ran all sort of freight and are looking forward to helping carriers have a
      stronger online presence.
      </p>

      <h2>How often do you update your site?</h2>

      <p>
        We take this site very seriously and intend to add many features to help carriers
        take full advantage of the internet. Carriers will be able to add more addresses, and describe their operation
        more accurately as we make the technology better. Thus it will result in more accurate searches so customers can find you better.
      </p>

      <h2>Contact Us</h2>

      <p>
      Please contact us at support@carriesfind.com, and we will do our best to meet your needs.
      </p>
</div>
);


export default AboutPage;
