import React from 'react';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';


class GoogleAnalytics extends React.Component {
    componentWillUpdate ({ location, history }) {
        if (history.action === 'PUSH') {
              ReactGA.initialize('UA-88630162-1');
              ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    render () {
        return null;
    }
}

export default withRouter(GoogleAnalytics);
