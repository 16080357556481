import React from 'react';
import ReactDOM from 'react-dom';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";

import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import './SignupDialog.css';
import {API_HOST_NAME} from './Environment.js';
import {isMobile} from 'react-device-detect';


class SigninDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      email: "",
    }
  }

  login = (response) => {
    if (response.headers.get("access-token") !== null &&
        response.headers.get("access-token") !== "") {
        localStorage.setItem(
          "access-token",
          response.headers.get("access-token")
        );
      }
      if (response.headers.get("uid") !== null) {
        localStorage.setItem("uid", response.headers.get("uid"));
      }
      if (response.headers.get("expiry") !== null) {
        localStorage.setItem("expiry", response.headers.get("expiry"));
      }
      if (response.headers.get("client") !== null) {
        localStorage.setItem("client", response.headers.get("client"));
      }
  }

  handleSignin = () => {
    fetch(`${API_HOST_NAME()}/auth/sign_in`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({email: this.state.email, password: this.state.password})
    })
      .then(response => {
        if (response.status > 400) {
          this.setState(() => {
            return {error: "Email or password not recognized."}
          })
        } else {
          this.login(response);
          return response.json();
        }
      }).then(json => {
        if (json) {
          this.props.setUser(json)
          this.props.closeDialog();
        }
      })
  }

  render() {

    var loginDialog = (
      <Dialog
        fullScreen={isMobile}
        maxWidth="md"
        open={this.props.openDialog}
        onClose={() => {
          this.setState(() => {
            return {error: null}
          });
          this.props.closeDialog()
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{className: 'signupDialog'}}
      >
        <DialogTitle id="alert-dialog-title" style={{padding: 0}}>
        <p className="medium-text" style={{fontFamily: 'Fira Sans', fontWeight: 600, fontStyle:'normal', textAlign: 'center', color: 'white', marginBottom: '30px', marginTop: '30px'}}>Login to Carrie's Find</p>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p style={{color: 'red', textAlign: 'center'}}> {this.state.error} </p>
            <form style={{ textAlign: 'center', marginTop: 0}}>
              <div style={{marginBottom: '8px'}}>
                <TextField  id="email" label="Company Email"
                            className="textfield"
                            variant="filled" onChange={(e) => {
                              const value = e.target.value;
                              this.setState(() => {return {email: value}})
                            }} />
              </div>
              <div style={{marginBottom: '8px'}}>
                <TextField  id="password"
                            className="textfield"
                            type="password"
                            label="Password"
                            variant="filled"
                            onChange={(e) => {
                              const value = e.target.value;
                              this.setState(() => {return {password: value}})
                }}
                />
              </div>
              <div style={{marginTop: '20px'}}>
                  <Button onClick={this.handleSignin} variant="contained" style={{width: '320px',backgroundColor: '#1A71D8', color: 'white', fontWeight: 600}}>Sign In</Button>
                    <p style={{marginBottom: 0}} onClick={this.props.showSignupDialog}> <u>New to search? Create an account.</u></p>
              </div>
          </form>
          </DialogContentText>
          { isMobile && (
            <DialogActions>
              <Button onClick={() => {
                this.setState(() => {
                  return {error: null}
                });
                this.props.closeDialog()
              }} color="primary">
              Cancel
              </Button>
            </DialogActions>

          )}
        </DialogContent>
      </Dialog>
    );

    if (this.props.showSigninDialog) {
      return (loginDialog);
    } else {
      return null;
    }
  }
}

export default SigninDialog;
