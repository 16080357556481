/* global woopra */
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import GoogleAnalytics from './GoogleAnalytics';
import { API_HOST_NAME } from "./Environment.js";

import logo from './assets/carriesfind-logo-only-white.svg';
import topLeftTrucks from './assets/cf-home-no-bg.png';
import './App.css';
import SearchResults from './SearchResults';
import SearchPage from './SearchPage';
import LoginBar from './LoginBar.js';
import Profile from './Profile.js';
import AboutPage from './AboutPage.js';
import HowToPage from './HowToPage.js';
import ScrollToTop from './ScrollToTop';
import Carrier from './Carrier';
import SignupDialog from './SignupDialog';
import SigninDialog from './SigninDialog';
import PrivacyPolicy from './PrivacyPolicy';
import Terms from './Terms';
import Geocoder from 'react-mapbox-gl-geocoder';

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import mapboxgl from 'mapbox-gl';
mapboxgl.accessToken = 'pk.eyJ1IjoibmFpbHVqMjciLCJhIjoiY2tleWVucDAxMGRxYjJybDU1c252YzFwayJ9.XMPMTxmObzFBrKnQTaGNYw';

const mapStyle = {
    width: '100%',
}
const params = {
    country: "us"
}

const mapboxApiKey ='pk.eyJ1IjoibmFpbHVqMjciLCJhIjoiY2tleWVucDAxMGRxYjJybDU1c252YzFwayJ9.XMPMTxmObzFBrKnQTaGNYw';

const randomGeos = {
  "Denver": [39.7392, -104.9903],
  "New York": [40.7128, -74.0060],
  "San Antonio": [29.4241, -98.4936],
  "Los Angeles": [34.0522, -118.2437],
  //"Cheyenne, Wyoming": [41.1400, -104.8202],
  "Milwaukee": [43.0389, -87.9065],
  "Columbus": [39.9612, -82.9988],
  "Houston": [29.7604, -95.3698],
  "Chicago": [41.8781, -87.6298],
  "Minneapolis": [44.9778, -93.2650]
}

class App extends React.Component {

  constructor(props) {
    super(props);

    var keys = Object.keys(randomGeos);
    var key = keys[Math.floor(Math.random()*keys.length)];
    var randomGeo = randomGeos[key];

    this.state = {
      viewport: {
        latitude: randomGeo[0],
        longitude: randomGeo[1],
        zoom: 10,
      },
      map: null,
      latitude: 38.4656,
      longitude: -94.61779,
      zoom: 10,
      filters: {
        cargo_carried: {},
        operation_classification: {},
        carrier_operation: {},
        id_numbers: {}
      },
      geocoderText: "",
      page: 1,
      carriers: [],
      showSignupDialog: false,
      showSigninDialog: false,
      user: null,
      safer_pages: [],
      showSearchAreaButton: false,
      initialInputValue: key,
      triggerSignup: false,
    }

  }


  componentDidMount() {

    // const random = Math.floor(Math.random() * 10);
    // if (random >= 5) {
    //   if (!this.state.triggerSignup) {
    //     this.setState((state, props) => {return {triggerSignup: true}},
    //       () => {
    //         setTimeout(this.showSignupDialog, 45000)
    //       }
    //     );
    //   }
    // }

    if (this.mapContainer) {
      const map = new mapboxgl.Map({
        container: this.mapContainer,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [this.state.longitude, this.state.latitude],
        zoom: this.state.zoom,
        types: 'country,region,postcode,locality,neighborhood'
      });
      var geocoder = new MapboxGeocoder({ // Initialize the geocoder
        accessToken: mapboxgl.accessToken, // Set the access token
        mapboxgl: mapboxgl, // Set the mapbox-gl instance
        marker: false, // Do not use the default marker style
        countries: 'us',
      });
      if(document.getElementById('geocoder')) {
        document.getElementById('geocoder').appendChild(geocoder.onAdd(map));
      }
      this.setState(() => {
        return {map: map}
      }, () => {
        this.state.map.on('move', () => {
          this.updateLongLat(
            this.state.map.getCenter().lng.toFixed(4),
            this.state.map.getCenter().lat.toFixed(4),
            this.state.map.getZoom().toFixed(2));
        });
      });
    }
    this.ping()

    // Idk if I want to ping the backend
    // setInterval(this.ping, 300000);
  }

  ping = () => {
    if (localStorage.getItem("uid")) {
      fetch(`${API_HOST_NAME()}/users`, {
        method: 'GET',
        headers: {'Content-Type': 'application/json',
        'uid': localStorage.getItem('uid'),
        'access-token': localStorage.getItem('access-token'),
        'expiry': localStorage.getItem('expiry'),
        'client': localStorage.getItem('client')

      }})
        .then(response => {
          return response.json()}
        ).then(json => {
          if (json.user) {
            this.setUser(json)
          } else {
            localStorage.removeItem('uid')
            localStorage.removeItem('access-token')
            localStorage.removeItem('expiry')
            localStorage.removeItem('client')
            this.setState({user: null})
          }
        }).catch(error => {
          localStorage.removeItem('uid')
          localStorage.removeItem('access-token')
          localStorage.removeItem('expiry')
          localStorage.removeItem('client')
          this.setState({user: null})
        })
    }
  }

  setUser = (user) => {
    this.setState(() => {return user},
    () => {
      woopra.identify({
          email: this.state.user?.uid,
        });
    }
    )
  }

  updateCarriers(json) {

    this.setState((state,props) => {
      return { carriers: json.carriers, carriers_count: json.carriers_count}
    });
    var text = document.querySelector(".cf-geocoder input");
    if (text){
      woopra.track("search", {
        search: text.value
      });
    }

    return json
  }

  updateLongLat(long, lat, zoom) {
    this.setState((state,props) => {
      return { longitude: long, latitude: lat, zoom: zoom}
    });
  }

  updateFilters = (type, name, value, doSearch=true) => {
    this.setState((state,props) => {
      const filters = Object.assign({}, this.state.filters);
      const filterType = filters[type];
      if (value) {
        filterType[name] = value;
      } else {
        delete filterType[name];
      }
      return { filters: filters}
    }, () => { if (doSearch) {this.searchArea(true)}});
  }

  setNextPage = (page) => {
    if (page === this.state.page) {
      return;
    }
    this.setState(() => { return {page: page}}, this.searchArea )
  }


  searchArea = (resetPage) => {
    // is new search, reset page
    if (resetPage) {
      this.setState({page: 1, showSearchAreaButton: false}, this.searchArea);
      return;
    }
    //api the lat lng
    //this.state.lng and append the points
    fetch(`${API_HOST_NAME()}/carriers/search?lat=${this.state.viewport.latitude}&long=${this.state.viewport.longitude}&zoom=${this.state.viewport.zoom}&page=${this.state.page -1}&filters=${JSON.stringify(this.state.filters)}`)
      .then(response => response.json())
      .then((json) => this.updateCarriers(json))
  }

  // signup stuff
  shouldSignup() {
    return !localStorage.getItem("uid") || (this.state.user && !this.state.user.uid)
  }

  showSignupDialog = (e, signupCallback) => {
    if (this.shouldSignup()) {
      this.setState(() => {return {showSignupDialog: true, signupCallback: signupCallback}})
      this.hideSigninDialog();
      return true
    }
    return false
  }

  hideSignupDialog = () => {
    this.setState(() => {return {showSignupDialog: false, signupCallback: null}})
  }

  showSigninDialog = () => {
    this.setState(() => {return {showSigninDialog: true}})
    this.hideSignupDialog();
  }

  hideSigninDialog = () => {
    this.setState(() => {return {showSigninDialog: false}})
  }


  logout = () => {
    localStorage.removeItem('uid')
    localStorage.removeItem('access-token')
    localStorage.removeItem('expiry')
    localStorage.removeItem('client')
    this.setState(() => { return {user: null}})
  }

  onSelected = (viewport, item) => {
    this.setState({
      viewport
    }, this.searchArea)
  }
setViewport = (viewport, item) => {
  this.setState({
    viewport
  })
  this.setState({showSearchAreaButton: true})
}
onSuggest = (viewport, item) => {
  this.setViewport(viewport)
}


  render() {
    const { viewport } = this.state;
    return (
      <div>
      <Router>
        <ScrollToTop />
        <Switch>
        <Route path="/carriers/:dot_number/business/:slug">
          <LoginBar
            onSelected={this.onSelected}
            viewport={viewport}
            queryParams={params}
            logout={this.logout}
            showSignupDialog={this.showSignupDialog}
            showSigninDialog={this.showSigninDialog}
            user={this.state.user}
            showGeocoder={false}
            ping={this.ping}
            searchArea={this.searchArea}
          />
            <Carrier
            onUpdateFilters={this.updateFilters}
            showSignupDialog={this.showSignupDialog} />
          </Route>
          <Route path="/carriers/:dot_number">
          <LoginBar
            onSelected={this.onSelected}
            viewport={viewport}
            queryParams={params}
            logout={this.logout}
            showSignupDialog={this.showSignupDialog}
            showSigninDialog={this.showSigninDialog}
            user={this.state.user}
            showGeocoder={false}
            ping={this.ping}
            searchArea={this.searchArea}
          />
            <Carrier
            onUpdateFilters={this.updateFilters}
            showSignupDialog={this.showSignupDialog} />
          </Route>

          <Route path="/profile">
            <LoginBar
              onSelected={this.onSelected}
              viewport={viewport}
              queryParams={params}
              logout={this.logout}
              showSignupDialog={this.showSignupDialog}
              showSigninDialog={this.showSigninDialog}
              user={this.state.user}
              showGeocoder={false}
              ping={this.ping}
              showMobileSearch={false}
            />

            <Profile logout={this.logout}/>
          </Route>

          <Route path="/privacy-policy">
            <LoginBar
              onSelected={this.onSelected}
              viewport={viewport}
              queryParams={params}
              logout={this.logout}
              showSignupDialog={this.showSignupDialog}
              showSigninDialog={this.showSigninDialog}
              user={this.state.user}
              showGeocoder={false}
              ping={this.ping}
              showMobileSearch={false}
            />
            <PrivacyPolicy />
          </Route>

          <Route path="/terms">
            <LoginBar
              onSelected={this.onSelected}
              viewport={viewport}
              queryParams={params}
              logout={this.logout}
              showSignupDialog={this.showSignupDialog}
              showSigninDialog={this.showSigninDialog}
              user={this.state.user}
              showGeocoder={false}
              ping={this.ping}
              showMobileSearch={false}
            />
            <Terms />
          </Route>

          <Route path="/about">
            <LoginBar
              onSelected={this.onSelected}
              viewport={viewport}
              queryParams={params}
              logout={this.logout}
              showSignupDialog={this.showSignupDialog}
              showSigninDialog={this.showSigninDialog}
              user={this.state.user}
              showGeocoder={false}
              ping={this.ping}
              showMobileSearch={false}
            />
            <AboutPage />

          </Route>

          <Route path="/how-to">
            <LoginBar
              onSelected={this.onSelected}
              viewport={viewport}
              queryParams={params}
              logout={this.logout}
              showSignupDialog={this.showSignupDialog}
              showSigninDialog={this.showSigninDialog}
              user={this.state.user}
              showGeocoder={false}
              ping={this.ping}
              showMobileSearch={false}
            />
            <HowToPage />

          </Route>


          <Route path="/">
            <LoginBar
              onSelected={this.onSelected}
              viewport={viewport}
              queryParams={params}
              logout={this.logout}
              showSignupDialog={this.showSignupDialog}
              showSigninDialog={this.showSigninDialog}
              user={this.state.user}
              showGeocoder={true}
              ping={this.ping}
              searchArea={this.searchArea}
              showMobileSearch={true}
            />
            <SearchPage
              carriers={this.state.carriers}
              carriers_count={this.state.carriers_count}
              filters={this.state.filters}
              longitude={this.state.longitude}
              latitude={this.state.latitude}
              zoom={this.state.zoom}
              updateLongLat={(long, lat, zoom) => this.updateLongLat(long, lat, zoom)}
              searchArea={this.searchArea}
              onUpdateFilters={this.updateFilters}
              onPageChange={this.setNextPage}
              page={this.state.page}
              showSignupDialog={this.showSignupDialog}
              viewport={this.state.viewport}
              setViewport={this.setViewport}
              showSearchAreaButton={this.state.showSearchAreaButton}
              params = {{country: "us"}}
              onSelected={this.onSelected}
              initialInputValue={this.state.initialInputValue}
            />
          </Route>

          <Route path="/">
          // <div className="App-header">
          //   <div width="700px" style={{position: 'fixed', top: 0, left: '0'}} id="lefttrucks"/>
          //   <div className="hero-text">
          //     <h1>Find any Carrier</h1>
          //     <div style={{margin: 'auto'}} id="geocoder" className="cf-geocoder" ></div>
          //     <Link to="/search">Search</Link>
          //     <div ref={el => this.mapContainer = el} style={{display: 'none'}}/>
          //   </div>
          // </div>
          </Route>

        </Switch>
        <div style={{margin: '20px auto', padding: '10px', maxWidth: '600px', width: '90%'}}>
          Carrie's Find is a website © 2021 Easy Tech Services, LLC. All Rights Reserved.
          <br/>
          <Link to="/about">About</Link>&nbsp;
          <Link to="/privacy-policy">Privacy Policy</Link>&nbsp;
          <Link to="/terms">Terms</Link>
          <br></br>
          For any help or questions contact support at support@carriesfind.com
          <br/>
          <p>Carrie's Find - the first search engine for carriers and the logistics industry</p>
        </div>

//        <GoogleAnalytics />
      </Router>

      <SignupDialog
        showSignupDialog={this.state.showSignupDialog}
        showSigninDialog={this.showSigninDialog}
        openDialog={this.showSignupDialog}
        closeDialog={this.hideSignupDialog}
        setUser={this.setUser}
        signupCallback={this.state.signupCallback}
        triggerSignup={this.state.triggerSignup}
       />
       <SigninDialog
         showSigninDialog={this.state.showSigninDialog}
         showSignupDialog={this.showSignupDialog}
         openDialog={this.showSigninDialog}
         closeDialog={this.hideSigninDialog}
         setUser={this.setUser}
        />
      </div>
    );
  }
}

// export const Home = () => {
//   return (
//   );
// }

export default App;
