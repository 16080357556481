import React from 'react';
import ReactDOM from 'react-dom';
import mapboxgl from 'mapbox-gl';
import './MenuDropDown.css';

class MenuDropDown extends React.Component {
  constructor(props) {
    super(props);
  }

  toggleDropDown = () => {
    if (this.props.openDropDown) {
      this.props.toggleDropDown(null)
    } else {
      this.props.toggleDropDown(this.props.buttonName)
    }
  }

  render() {
    return (
      <div className={`dropdown ${this.props.className || ""}`}>
          <p className="medium-text"> {this.props.buttonName} </p>
            {this.props.menuItems}

            {
              !this.props.noDropDown && <span className="reg-text" style={{marginLeft: '4px', color: '#1A71D8', fontWeight: 600}} onClick={this.toggleDropDown} > {
                this.props.openDropDown ? "Show Less" : "Show All"
              }</span>
            }

      </div>
    )
  }
}

export default MenuDropDown;
