/* global woopra */
import React from 'react';
import ReactDOM from 'react-dom';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";

import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import './SignupDialog.css';
import {API_HOST_NAME} from './Environment.js';
import {isMobile} from 'react-device-detect';


class SignupDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      password: "",
      email: "",
    }

  }

  login = (response) => {
    if (response.headers.get("access-token") !== null &&
        response.headers.get("access-token") !== "") {
        localStorage.setItem(
          "access-token",
          response.headers.get("access-token")
        );
      }
      if (response.headers.get("uid") !== null) {
        localStorage.setItem("uid", response.headers.get("uid"));
      }
      if (response.headers.get("expiry") !== null) {
        localStorage.setItem("expiry", response.headers.get("expiry"));
      }
      if (response.headers.get("client") !== null) {
        localStorage.setItem("client", response.headers.get("client"));
      }
  }

  handleSubmit = () => {

    const data = {
      user: {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        password: this.state.password
      }
    }
    fetch(`${API_HOST_NAME()}/auth`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data)
    })
      .then(response => {
        if (response.status > 400) {
          this.setState(() => {
            return {error: "Please check information or login if you have an account."}
          })
        } else {
          this.login(response);
        }
        return response.json()})
      .then(json => {
        this.props.setUser(json)
        if (this.props.signupCallback) {
          this.props.signupCallback();
        }
        this.props.closeDialog()
        woopra.track("signup", {
          signup: json.user.uid
        });
      });
  }

  render() {

    var signupDialog = (
      <Dialog
        fullScreen={isMobile}
        maxWidth="md"
        open={this.props.openDialog}
        onClose={() => {
          this.setState(() => {
            return {error: null}
          });
          this.props.closeDialog()
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{className: 'signupDialog'}}
      >
        <DialogTitle id="alert-dialog-title" style={{padding: 0}}>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p className="big-text" style={{textAlign: 'center', color: 'white', marginBottom: '20px', marginTop: '30px'}}>
              Create an account
            </p>
            <p style={{color: 'red', textAlign: 'center', fontSize: '16px'}}> {this.state.error} </p>

            <form style={{height: '380px', textAlign: 'center', marginTop: 0}}>

                <div style={{marginBottom: '8px'}}>
                  <TextField  id="first" label="First Name"
                    className="textfield"
                    variant="filled" onChange={(e) => {
                      const value = e.target.value;
                      this.setState(() => {return {first_name: value}})
                    }}
                  />
                </div>

                <div style={{marginBottom: '8px'}}>
                  <TextField
                    id="last" label="Last Name"
                    className="textfield"
                    variant="filled" onChange={(e) => {
                      const value = e.target.value;
                      this.setState(() => {return {last_name: value}})
                    }} />
                </div>

              <div style={{marginBottom: '8px'}}>
                <TextField  id="email" label="Company Email"
                            className="textfield"
                            variant="filled" onChange={(e) => {
                              const value = e.target.value;
                              this.setState(() => {return {email: value}})
                            }} />
              </div>
              <div style={{marginBottom: '8px'}}>
                <TextField  id="password"
                            className="textfield"
                            type="password"
                            label="Password"
                            variant="filled"
                            onChange={(e) => {
                              const value = e.target.value;
                              this.setState(() => {return {password: value}})
                            }}
                />
                <p style={{margin: 0, marginLeft: '-117px'}}>Please enter 8+ characters.</p>
              </div>
              <div style={{marginTop: '20px'}}>
                <Button onClick={this.handleSubmit} style={{width: '320px',backgroundColor: '#1A71D8', color: 'white', fontWeight: 600}} variant="contained">Get Started</Button>
                <p style={{marginBottom: 0}} onClick={this.props.showSigninDialog}> <u>Have an account? Log in. </u></p>
                <p style={{margin: 0}}> By signing up, you agree to the <a href="/terms">terms</a>. </p>
              </div>
          </form>
          </DialogContentText>
          { isMobile && (
            <DialogActions>
              <Button onClick={() => {
                this.setState(() => {
                  return {error: null}
                });
                this.props.closeDialog()
              }} color="primary">
              Cancel
              </Button>
            </DialogActions>

          )}


        </DialogContent>
      </Dialog>
    )

    if (this.props.showSignupDialog) {
      return signupDialog;
    } else {
      return null;
    }
  }
}

export default SignupDialog;
