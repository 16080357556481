

import React from 'react';


const Terms = (props) => (
  <div style={{margin: 'auto', width: '650px'}}>
    <h1> Terms of Service </h1>
     <p><strong>Last Updated on February 1, 2021</strong>, These terms are effective immediately for users accessing or using the Service with or without an Account.</p>
     <p></p>
     <p></p>
     <p><strong>PLEASE NOTE: THESE TERMS INCLUDE DISPUTE RESOLUTION PROVISIONS (SEE SECTION 13) THAT, WITH LIMITED EXCEPTIONS, REQUIRE THAT (1) CLAIMS YOU BRING AGAINST Carrie's Find BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION, AND (2) YOU WAIVE YOUR RIGHT TO BRING OR PARTICIPATE IN ANY CLASS, GROUP, OR REPRESENTATIVE ACTION OR PROCEEDING.</strong></p>
     <p></p>
     <p></p>
     <p>These Terms govern your access to and use of our products and services, including those offered through our websites, events, communications (e.g., emails, phone calls, and texts) and mobile applications (collectively, the “<strong>Service</strong>”). By accessing or using the Service, you are agreeing to these Terms, which form a legally binding contract with: (i) Easy Tech Services, LLC., an LLC with its headquarters in Sunny Isles Beach, Florida
     <strong>“Carrie's Find”</strong> means Easy Tech Services, LLC, as applicable. Do not access or use the Service if you are unwilling or unable to be bound by the Terms. For more information about our policies and instructions relating to the Service, contact us at legal@carriesfind.com.</p>
     <p></p>
     <p></p>
     <ol>
        <li>
           <strong>DEFINITIONS</strong>
           <ol>
              <li><strong>Parties. </strong>“<strong>You</strong>”&nbsp;and&nbsp;“<strong>your</strong>”&nbsp;refer to you, as a user of the Service. A&nbsp;“<strong>user</strong>”&nbsp;is someone who accesses or in any way uses the Service. “<strong>We</strong>,”&nbsp;“<strong>Carries' Find</strong> or us,”&nbsp;and&nbsp;“<strong>our</strong>”&nbsp;refer to Easy Tech Services, LLC and its subsidiaries.</li>
              <li><strong>Content. </strong>“<strong>Content</strong>”&nbsp;means text, images, photos, audio, video, and all other forms of data or communication.&nbsp;“<strong>Your Content</strong>”&nbsp;means Content that you submit or transmit to, through, or in connection with the Service, such as ratings, reviews, photos, videos, compliments, invitations, check-ins, votes, friending and following activity, direct messages, and information that you contribute to your user profile or suggest for a business page.&nbsp;“<strong>User Content</strong>”&nbsp;means Content that users submit or transmit to, through, or in connection with the Service.&nbsp;“<strong>Carries' Find Content</strong>”&nbsp;means Content that we create and make available in connection with the Service. “<strong>Third Party Content</strong>”&nbsp;means Content that originates from parties other than Carries' Find or its users, which is made available in connection with the Service.&nbsp;“<strong>Service Content</strong>”&nbsp;means all of the Content that is made available in connection with the Service, including Your Content, User Content, Carries' Find Content, and Third Party Content.</li>
              <li>&nbsp;&nbsp;<strong>Sites and Accounts. “Consumer Site” </strong>means Carries' Find’s consumer website (www.carriesfind.com and related domains) and mobile applications. <strong>“Consumer Account”</strong> means the account you create to access or use the Consumer Site. “<strong>Business Account”</strong> means the account you create to access or use the Carries' Find for Business Owners website (biz.Carries' Find.com and related domains) and mobile applications. “<strong>Account</strong>” means any Consumer Account or Business Account.</li>
           </ol>
        </li>
        <li><strong>CHANGES TO THE TERMS<br/></strong>We may modify the Terms from time to time. The most current version of the Terms will be located on this page. You understand and agree that your access to or use of the Service is governed by the Terms effective at the time of your access to or use of the Service. If we make material changes to these Terms, we will notify you by email, by posting notice on the Service, and/or by other method prior to the effective date of the changes. We will also indicate at the top of this page the date that such changes were last made. You should revisit these Terms on a regular basis as revised versions will be binding on you. <strong>You understand and agree that your continued access to or use of the Service after the effective date of changes to the Terms represents your acceptance of such changes.</strong></li>
        <li><strong>TRANSLATION<br/></strong>We may translate these Terms into other languages for your convenience. Nevertheless, the English version governs your relationship with Carries' Find, and any inconsistencies among the different versions will be resolved in favor of the English version available here.</li>
        <li>
           <strong>USING THE SERVICE</strong>
           <ol>
              <li><strong>Eligibility. </strong>To access or use the Service, you must have the requisite power and authority to enter into these Terms. You may not access or use the Service if you are a competitor of Carries' Find or if we have previously banned you from the Service or closed your Account.</li>
              <li><strong>Permission to Use the Service. </strong>We grant you permission to use the Service subject to these Terms. Your use of the Service is at your own risk, including the risk that you might be exposed to Content that is offensive, indecent, inaccurate, objectionable, incomplete, fails to provide adequate warning about potential risks or hazards, or is otherwise inappropriate.</li>
              <li><strong>Service Availability. </strong>The Service may be modified, updated, interrupted, suspended or discontinued at any time without notice or liability.</li>
              <li>
                 <strong>Accounts. </strong>You must create an Account and provide certain information about yourself in order to use some of the features that are offered through the Service. You are responsible for maintaining the confidentiality of your Account password. You are also responsible for all activities that occur in connection with your Account. You agree to notify us immediately of any unauthorized use of your Account. We reserve the right to close your Account at any time for any or no reason.
                 <p>Your Consumer Account is for your personal, non-commercial use only, and you may not create or use a Consumer Account for anyone other than yourself. We ask that you provide complete and accurate information about yourself when creating an Account in order to bolster your credibility as a contributor to the Service. You may not impersonate someone else, provide an email address other than your own, create multiple Accounts, or transfer your Consumer Account to another person without Carries' Find’s prior approval.&nbsp;</p>
              </li>
              <li>
                 <strong>Communications from Carries' Find and Others. </strong>By accessing or using the Service, you consent to receive communications from other users and Carries' Find through the Service, or through any other means such as emails, push notifications, text messages (including SMS and MMS), and phone calls. These communications may promote Carries' Find or businesses listed on Carries' Find, and may be initiated by Carries' Find, businesses listed on Carries' Find, or other users. You further understand that communications may be sent using an automatic telephone dialing system, and that you may be charged by your phone carrier for certain communications such as SMS messages or phone calls. You agree to notify us immediately if the phone number(s) you have provided to us have been changed or disconnected. Please note that any communications, including phone calls, with Carries' Find or made through the Service may be monitored and recorded for quality purposes.
                 <p>You can opt-out of certain communications here.</p>
              </li>
           </ol>
        </li>
        <li>
           <strong>CONTENT</strong>
           <ol>
              <li>
                 <strong>Responsibility for Your Content. </strong>You alone are responsible for Your Content, and once posted to Carries' Find, it cannot always be withdrawn. You assume all risks associated with Your Content, including anyone’s reliance on its quality, accuracy, or reliability, and any risks associated with personal information you disclose. You represent that you own or have the necessary permissions to use and authorize the use of Your Content as described herein. You may not imply that Your Content is in any way sponsored or endorsed by Carries' Find.
                 <p>You may expose yourself to liability if, for example, Your Content contains material that is false, intentionally misleading, or defamatory; violates any third-party right, including any copyright, trademark, service mark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; contains material that is unlawful, including illegal hate speech or pornography; exploits or otherwise harms minors; violates or advocates the violation of any law or regulation; or violates these Terms.</p>
              </li>
              <li><strong>Our Right to Use Your Content. </strong>We may use Your Content in a number of different ways, including by publicly displaying it, reformatting it, incorporating it into advertisements and other works, creating derivative works from it, promoting it, distributing it, and allowing others to do the same in connection with their own websites and media platforms (“<strong>Other Media</strong>”). As such, you hereby irrevocably grant us world-wide, perpetual, non-exclusive, royalty-free, assignable, sublicensable, transferable rights to use Your Content for any purpose. Please note that you also irrevocably grant the users of the Service and any Other Media the right to access Your Content in connection with their use of the Service and any Other Media. Finally, you irrevocably waive, and cause to be waived, against Carries' Find and its users any claims and assertions of moral rights or attribution with respect to Your Content. By “<strong>use</strong>” we mean use, copy, publicly perform and display, reproduce, distribute, modify, translate, remove, analyze, commercialize, and prepare derivative works of Your Content.</li>
              <li><strong>Ownership. </strong>As between you and Carries' Find, you own Your Content. We own the Carries' Find Content, including but not limited to visual interfaces, interactive features, graphics, design, compilation (including, but not limited to, our selection, coordination, aggregation, and arrangement of User Content and other Service Content), computer code, products, software, aggregate star ratings, and all other elements and components of the Service excluding Your Content, User Content and Third Party Content. We also own the copyrights, trademarks, service marks, trade names, trade secrets, and other intellectual and proprietary rights throughout the world associated with the Carries' Find Content and the Service, which are protected by copyright, trade dress, patent, trademark, and trade secret laws and all other applicable intellectual and proprietary rights and laws. As such, you may not sell, license, copy, publish, modify, reproduce, distribute, create derivative works or adaptations of, publicly display or in any way use or exploit any of the Carries' Find Content in whole or in part except as expressly authorized by us. Except as expressly and unambiguously provided herein, we do not grant you any express or implied rights, and all rights in and to the Service and the Carries' Find Content are retained by us.</li>
              <li><strong>Advertising. </strong>Carries' Find and its licensees may publicly display advertisements, paid content, and other information nearby or in association with Your Content. You are not entitled to any compensation for such advertisements. The manner, mode and extent of such advertising are subject to change without specific notice to you.</li>
              <li><strong>Other. </strong>User Content (including any that may have been created by users employed or contracted by Carries' Find) does not necessarily reflect the opinion of Carries' Find. Except as required by law, we have no obligation to retain or provide you with copies of Your Content, and we do not guarantee any confidentiality with respect to Your Content. We reserve the right to remove, screen, edit, or reinstate User Content at our sole discretion for any reason or no reason, and without notice to you. For example, we may remove a review if we believe it violates our terms. Except in accordance with Carries' Find’s Verified License program, Carries' Find does not attempt to verify any licenses a local business or its representatives may have, and consumers should inquire about any such licenses with the business directly. Businesses whose licenses have been verified by Carries' Find will have a “Verified License” badge displayed on their Carries' Find business page.</li>
           </ol>
        </li>
        <li>
           <strong>BOOKING AND TRANSACTING</strong>
           <ol>
              <li><strong>Generally.</strong> You can access features through the Service that allow you to book or transact online with local businesses, such as making restaurant or spa reservations, ordering food delivery, or scheduling appointments. These features may be provided by Carries' Find’s third-party partners, including through iframes or similar formats, and their use may be governed by different or additional terms presented to you as part of the booking or transaction process. Please note that such third-party partners and/or the transacting local businesses themselves are responsible for fulfilling such bookings and transactions.</li>
              <li><strong>Payments and Cancellations. </strong>You may be required to provide your credit card information to confirm a booking, and will be charged any applicable fees, including cancellation or no-show fees in accordance with the transacting local business’s cancellation policy provided at the time of booking. You agree that Carries' Find may facilitate any such payments and charges on behalf of the transacting local business.</li>
              <li><strong>Coupons.</strong> Any coupons that Carries' Find might issue for use in connection with the Service are non-transferable (unless required by law), not redeemable for cash or any other consideration, and automatically expire thirty (30) days after the issue date unless otherwise specified. If your Account is terminated you will not be able to use any unexpired and unused coupons, and any such coupons will automatically terminate and cannot be redeemed unless required by law.&nbsp;</li>
           </ol>
        </li>
        <li>
           <strong>REPRESENTATIONS AND WARRANTIES<br/></strong>We are under no obligation to enforce the Terms on your behalf against another user. While we encourage you to let us know if you believe another user has violated the Terms, we reserve the right to investigate and take appropriate action at our sole discretion.
           <ol>
              <li>
                 You represent and warrant that:&nbsp;
                 <ol>
                    <li>You have read and understood our terms;</li>
                    <li>You have read and understood our&nbsp;<a href="https://carriesfind.com/privacy-policy">Privacy Policy</a>. If you use the Service outside of the United States of America, you consent to having your personal data transferred to and processed in the United States of America; and&nbsp;</li>
                    <li>Prior to attending any event listed on the Service, you have read and agree to our Event Terms and Conditions.&nbsp;</li>
                 </ol>
              </li>
              <li>
                 You also represent and warrant that you will not, and will not assist, encourage, or enable others to use the Service to:
                 <ol>
                    <li>Violate our Terms, including the Content Guidelines and Event Terms and Conditions;</li>
                    <li>Post any fake or defamatory review, trade reviews with others, or compensate someone or be compensated to post, refrain from posting, or remove a review;</li>
                    <li>Violate any third party’s rights, including any breach of confidence, copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right;</li>
                    <li>Threaten, stalk, harm, or harass others, or promote bigotry or discrimination;</li>
                    <li>Promote a business or other commercial venture or event, or otherwise use the Service for commercial purposes, except in connection with a Business Account in accordance with the Business Terms;</li>
                    <li>Send bulk emails, surveys, or other mass messaging, whether commercial in nature or not; engage in keyword spamming, or otherwise attempt to manipulate the Service’s search results, review Recommendation Software (as defined in the Business Terms below), or any third party website;</li>
                    <li>Solicit personal information from minors, or submit or transmit pornography;</li>
                    <li>Violate any applicable law;</li>
                    <li>Modify, adapt, appropriate, reproduce, distribute, translate, create derivative works or adaptations of, publicly display, sell, trade, or in any way exploit the Service or Service Content (other than Your Content), except as expressly authorized by Carries' Find;</li>
                    <li>Use any robot, spider, Service search/retrieval application, or other automated device, process or means to access, retrieve, copy, scrape, or index any portion of the Service or any Service Content, except as expressly permitted by Carries' Find (for example, as described at www.Carries' Find.com/robots.txt);</li>
                    <li>Reverse engineer any portion of the Service, unless applicable law prohibits this restriction, in which case you agree to provide us with&nbsp; 30 days’ prior written notice&nbsp;</li>
                    <li>Remove or modify any copyright, trademark, or other proprietary rights notice that appears on any portion of the Service or on any materials printed or copied from the Service;</li>
                    <li>Record, process, or mine information about users;</li>
                    <li>Access, retrieve or index any portion of the Service for purposes of constructing or populating a searchable database of business reviews;</li>
                    <li>Reformat or frame any portion of the Service;</li>
                    <li>Take any action that imposes, or may impose, in our sole discretion, an unreasonable or disproportionately large load on Carries' Find’s technology infrastructure or otherwise make excessive traffic demands of the Service;</li>
                    <li>Attempt to gain unauthorized access to the Service, Accounts, computer systems or networks connected to the Service through hacking, password mining or any other means;</li>
                    <li>Use the Service or any Service Content to transmit any computer viruses, worms, defects, Trojan horses, malicious code, spyware, malware or other items of a destructive or harmful nature;</li>
                    <li>Use any device, software or routine that interferes with the proper working of the Service, or otherwise attempt to interfere with the proper working of the Service;</li>
                    <li>Use the Service to violate the security of any computer network, crack passwords or security encryption codes; disrupt or interfere with the security of, or otherwise cause harm to, the Service or Service Content; or</li>
                    <li>Remove, circumvent, disable, damage or otherwise interfere with any security-related features of the Service, features that prevent or restrict the use or copying of Service Content, or features that enforce limitations on the use of the Service.</li>
                 </ol>
              </li>
           </ol>
        </li>
        <li>
           <strong>ADDITIONAL POLICIES AND TERMS&nbsp;</strong>
           <ol>
              <li><strong>Copyright and Trademark Disputes. </strong>You agree to follow our&nbsp;Infringement Policy&nbsp;in notifying us about copyright and trademark disputes concerning User Content. You agree we may forward any notification sent pursuant to our Infringement Policy to the user who submitted the User Content at issue.</li>
              <li><strong>Additional Terms. </strong>Your use of the Service is subject to any and all additional terms, policies, rules, or guidelines that we may post on or link to from the Service (the “<strong>Additional Terms</strong>”). All such Additional Terms are hereby incorporated by reference into, and made a part of, these Terms. If you have a Business Account, the Business Terms provided below apply to you.&nbsp;</li>
           </ol>
        </li>
        <li><strong>SUGGESTIONS AND IMPROVEMENTS<br/></strong>By sending us any ideas, suggestions, documents or proposals (“<strong>Feedback</strong>”), you agree that (i) your Feedback does not contain any third party confidential or proprietary information, (ii) we are under no obligation of confidentiality, express or implied, with respect to the Feedback, (iii) we may have something similar to the Feedback already under consideration or in development, (iv) we have no obligation to review, consider, or implement the Feedback, or to return to you all or part of the Feedback, and (v) you grant us an irrevocable, non-exclusive, royalty-free, perpetual, worldwide, assignable, sublicensable, transferable license to use, modify, prepare derivative works of, publish, distribute and sublicense the Feedback, and you irrevocably waive, and cause to be waived, against Carries' Find and its users any claims and assertions of any moral rights contained in such Feedback.</li>
        <li>
           <strong>THIRD PARTY CONTENT AND SERVICES</strong>
           <ol>
              <li>
                 The Service may host Third Party Content, or include links to other websites or applications (each, a&nbsp;“<strong>Third Party Service</strong>”). We do not control or endorse any Third Party Content or Third Party Service. You agree that we are not responsible for the availability, accuracy, or content of any such Third Party Content or Third Party Service. Your use of and reliance on any Third Party Content or Third Party Service is at your own risk.
                 <p>Some of the services made available through the Service and Third Party Services may be subject to additional third party terms of service, privacy policies, licensing terms and disclosures, and other terms, conditions, and policies, including without limitation the ones posted here. It is your responsibility to familiarize yourself with any such applicable third party terms.</p>
              </li>
           </ol>
        </li>
        <li><strong>INDEMNITY<br/></strong>You agree to indemnify, defend, and hold harmless Carries' Find, its parents, subsidiaries, affiliates, any related companies, suppliers, licensors and partners, and the officers, directors, employees, agents, contractors and representatives of each of them (collectively, the “<strong>Carries' Find Entities</strong>”) from&nbsp; and against any and all third party claims, actions, demands, losses, damages, costs, liabilities and expenses (including but not limited to attorneys’ fees and court costs)&nbsp; arising out of or relating to: (i) your access to or use of the Service, including Your Content, (ii) your violation of the Terms, (iii) your breach of your representations and warranties provided under these Terms, (iv) any products or services purchased or obtained by you in connection with the Service, (v) your products or services, or the marketing or provision thereof to end users, or (vi) the infringement by you, or any third party using your Account, of any intellectual property or other right of any person or entity. Carries' Find reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us and you agree to cooperate with our defense of these claims. You agree not to settle any such matter without the prior written consent of Carries' Find. Carries' Find will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.</li>
        <li>
           <strong>DISCLAIMERS AND LIMITATIONS OF LIABILITY<br/></strong><strong>PLEASE READ THIS SECTION CAREFULLY SINCE IT LIMITS THE LIABILITY OF THE Carries' Find ENTITIES TO YOU. EACH OF THE SUBSECTIONS BELOW ONLY APPLIES UP TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW. NOTHING HEREIN IS INTENDED TO LIMIT ANY RIGHTS YOU MAY HAVE WHICH MAY NOT BE LAWFULLY LIMITED. BY ACCESSING OR USING THE SERVICE, YOU REPRESENT THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO THESE TERMS, INCLUDING THIS SECTION. YOU ARE GIVING UP SUBSTANTIAL LEGAL RIGHTS BY AGREEING TO THESE TERMS.</strong>
           <ol>
              <li>THE SERVICE AND SERVICE CONTENT ARE MADE AVAILABLE TO YOU ON AN “AS IS”, “WITH ALL FAULTS” AND “AS AVAILABLE” BASIS, WITH THE EXPRESS UNDERSTANDING THAT THE Carries' Find ENTITIES MAY NOT MONITOR, CONTROL, OR VET USER CONTENT OR THIRD PARTY CONTENT. AS SUCH, YOUR USE OF THE SERVICE IS AT YOUR OWN DISCRETION AND RISK. THE Carries' Find ENTITIES MAKE NO CLAIMS OR PROMISES ABOUT THE QUALITY, COMPLETENESS, ACCURACY, OR RELIABILITY OF THE SERVICE, ITS SAFETY OR SECURITY, INCLUDING WITHOUT LIMITATION THE SECURITY OF YOUR DATA, OR THE SERVICE CONTENT. ACCORDINGLY, THE Carries' Find ENTITIES ARE NOT LIABLE TO YOU FOR ANY PERSONAL INJURY, LOSS OR DAMAGE THAT MIGHT ARISE, FOR EXAMPLE, FROM THE SERVICE’S INOPERABILITY, DEPLETION OF BATTERY POWER OR OTHER IMPAIRMENT OF DEVICES USED TO ACCESS THE SERVICE, SERVICE UNAVAILABILITY, SECURITY VULNERABILITIES OR FROM YOUR RELIANCE ON THE QUALITY, ACCURACY, OR RELIABILITY OF THE BUSINESS LISTINGS, RATINGS, REVIEWS (INCLUDING THEIR CONTENT OR OMISSION OF CONTENT, ORDER, AND DISPLAY), METRICS OR OTHER CONTENT FOUND ON, USED ON, OR MADE AVAILABLE THROUGH THE SERVICE.</li>
              <li>THE Carries' Find ENTITIES MAKE NO CLAIMS OR PROMISES WITH RESPECT TO ANY THIRD PARTY, SUCH AS THE BUSINESSES OR ADVERTISERS LISTED ON THE SERVICE OR THAT OFFER GOODS OR SERVICES THROUGH THE SERVICE, OR THE SERVICE’S USERS. ACCORDINGLY, THE Carries' Find ENTITIES ARE NOT LIABLE TO YOU FOR ANY PERSONAL INJURY, LOSS OR DAMAGE THAT MIGHT ARISE FROM ANY SUCH THIRD PARTY’S ACTIONS OR OMISSIONS, INCLUDING, FOR EXAMPLE, IF ANOTHER USER OR BUSINESS MISUSES YOUR CONTENT, IDENTITY OR PERSONAL INFORMATION, OR IF YOU HAVE A NEGATIVE EXPERIENCE WITH ONE OF THE BUSINESSES OR ADVERTISERS LISTED OR FEATURED ON THE SERVICE. YOUR PURCHASE AND USE OF PRODUCTS OR SERVICES OFFERED BY THIRD PARTIES THROUGH THE SERVICE IS AT YOUR OWN DISCRETION AND RISK.</li>
              <li>YOUR SOLE AND EXCLUSIVE RIGHT AND REMEDY IN CASE OF DISSATISFACTION WITH THE SERVICE, RELATED SERVICES, OR ANY OTHER GRIEVANCE SHALL BE YOUR TERMINATION AND DISCONTINUATION OF ACCESS TO, OR USE OF THE SERVICE.</li>
              <li>THE Carries' Find ENTITIES’ MAXIMUM AGGREGATE LIABILITY TO YOU FOR LOSSES OR DAMAGES THAT YOU SUFFER IN CONNECTION WITH THE SERVICE OR THESE TERMS IS LIMITED TO THE GREATER OF (i) THE AMOUNT PAID, IF ANY, BY YOU TO THE Carries' Find ENTITIES IN CONNECTION WITH THE SERVICE IN THE 12 MONTHS PRIOR TO THE ACTION GIVING RISE TO LIABILITY, OR (ii) $100.</li>
              <li>THE Carries' Find ENTITIES’ LIABILITY SHALL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE Carries' Find ENTITIES WILL NOT BE LIABLE FOR ANY (i) INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, RELIANCE, OR CONSEQUENTIAL DAMAGES, (ii) LOSS OF PROFITS OR REVENUE, (iii) BUSINESS INTERRUPTION, (iv) REPUTATIONAL HARM, (v) LOSS OF INFORMATION OR DATA; OR (vi) LIABILITY WITH RESPECT TO A CONSUMER ALERT POSTED ON ANY Carries' Find BUSINESS PAGES FOR YOUR BUSINESS. THE WAIVERS AND LIMITATIONS SPECIFIED IN THIS SECTION 12 WILL SURVIVE AND APPLY REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE.</li>
           </ol>
        </li>
        <li>
           <strong>ARBITRATION, DISPUTES, AND CHOICE OF LAW</strong>
           <ol>
              <li>
                 If you are a resident of the United States or Canada:
                 <ol>
                    <li>EXCEPT FOR EXCLUDED CLAIMS: ANY CLAIM, CAUSE OF ACTION, REQUEST FOR RELIEF OR DISPUTE THAT MIGHT ARISE BETWEEN YOU AND Carries' Find (“<strong>CLAIMS</strong>”) MUST BE RESOLVED BY ARBITRATION ON AN INDIVIDUAL BASIS; YOU AND WE AGREE THAT EACH MAY BRING OR PARTICIPATE IN CLAIMS AGAINST THE OTHER ONLY IN OUR RESPECTIVE INDIVIDUAL CAPACITIES, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. UNLESS BOTH YOU AND Carries' Find AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN THE CLAIMS OF OTHER PERSONS OR PARTIES WHO MAY BE SIMILARLY SITUATED, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING. YOU AND Carries' Find EXPRESSLY WAIVE THE RIGHT TO TRIAL BY A JURY.</li>
                    <li>“<strong>Excluded Claims</strong>” means: (a) Claims brought by you or Carries' Find that could be brought in small claims court, if permitted by the rules of that court, or (b) Claims related to intellectual property (like copyrights and trademarks), violations of Carries' Find’s API Terms of Use (which, for clarity, are governed by those terms), or a breach of Section 7 above (Representations and Warranties). Excluded Claims may be brought in court. Any issues relating to the scope and enforceability of the arbitration provision will be resolved by the arbitrator. If any Claim cannot be arbitrated in accordance with this provision, then only that Claim may be brought in court and all other Claims remain subject to arbitration. Notwithstanding this paragraph, Business Claims, as defined in Section 3 of the Additional Terms for Business Accounts, are governed by that section.</li>
                    <li>Excluded Claims and Claims that cannot be arbitrated must be brought in court. California law will govern these Terms (to the extent not preempted or inconsistent with federal law), as well as any such Excluded Claim or Claim that cannot be arbitrated, without regard to conflict of law provisions. You or Carries' Find may seek relief in any small claims court of competent jurisdiction. All other Excluded Claims and Claims that cannot be arbitrated are subject to the exclusive jurisdiction in, and the exclusive venue of, the state and federal courts located within San Francisco County, California and you consent to the personal jurisdiction of these courts for the purpose of litigating any such Claim.</li>
                    <li>Arbitration shall be administered by the American Arbitration Association (“AAA”) in accordance with its <a href="https://www.adr.org/sites/default/files/Consumer%20Rules.pdf">Consumer Arbitration Rules</a> then in effect. For more information, visit <a href="http://www.adr.org">www.adr.org</a>. Arbitration may be conducted in person, through the submission of documents, by phone or online. The arbitrator may award damages to you individually as a court could, including declaratory or injunctive relief, but only to the extent required to satisfy your individual claim.</li>
                    <li>Unless the arbitrator finds the arbitration was frivolous or brought for an improper purpose, Carries' Find will pay all filing, AAA, and arbitrator’s fees and expenses. We waiveany right to seek an award of attorneys’ fees and expenses in connection with any non-frivolous arbitration between you and us.</li>
                 </ol>
              </li>
              <li>
                 If you are a resident of a country located in the EEA or Switzerland, Irish law will govern these Terms, as well as any dispute that might arise between you and Carries' Find.
                 <ol>
                    <li>You agree that any subpoena, third-party discovery request, or other third-party process directed to Carries' Find must issue from, or be domesticated by, the state or federal courts located within San Francisco County, California and you agree to submit to the jurisdiction of each of these courts for any related proceedings.&nbsp;</li>
                 </ol>
              </li>
           </ol>
        </li>
        <li>
           <strong>TERMINATION</strong>
           <ol>
              <li>You may terminate the Terms at any time by closing your Account, discontinuing any access to or use of the Service, and providing Carries' Find with a notice of termination&nbsp;.&nbsp;</li>
              <li>We may close your Account, suspend your ability to use certain portions of the Service, terminate any license or permission granted to you hereunder, and/or ban you altogether from the Service for any or no reason, and without notice or liability of any kind. Any such action could prevent you from accessing your Account, the Service, Your Content, Service Content, or any other related information.</li>
              <li>In the event of any termination of these Terms, whether by you or us, Sections 1, 3,&nbsp; 5, 7–15 of the Terms of Service will continue in full force and effect.</li>
           </ol>
        </li>
        <li>
           <strong>GENERAL TERMS</strong>
           <ol>
              <li>We reserve the right to modify, update, or discontinue the Service at our sole discretion, at any time, for any or no reason, and without notice or liability.</li>
              <li>Except as otherwise stated in Section 10 above, nothing herein is intended, nor will be deemed, to confer rights or remedies upon any third party.</li>
              <li>The Terms contain the entire agreement between you and us regarding the use of the Service, and supersede any prior agreement between you and us on such subject matter. The parties acknowledge that no reliance is placed on any representation made but not expressly contained in these Terms.</li>
              <li>Any failure on Carries' Find’s part to exercise or enforce any right or provision of the Terms does not constitute a waiver of such right or provision. The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder. The Terms may not be waived, except pursuant to a writing executed by Carries' Find.</li>
              <li>If any provision of the Terms is found to be unenforceable or invalid by an arbitrator or court of competent jurisdiction, then only that provision shall be modified to reflect the parties’ intention or eliminated to the minimum extent necessary so that the Terms shall otherwise remain in full force and effect and enforceable.</li>
              <li>The Terms, and any rights or obligations hereunder, are not assignable, transferable or sublicensable by you except with Carries' Find’s prior written consent, but may be assigned or transferred by us without restriction. Any attempted assignment by you shall violate these Terms and be void.</li>
              <li>You agree that no joint venture, partnership, employment, agency, special or fiduciary relationship exists between you and Carries' Find as a result of these Terms or your use of the Service.&nbsp;</li>
              <li>The section titles in the Terms are for convenience only and have no legal or contractual effect.</li>
           </ol>
        </li>
     </ol>

     <p></p>
     <p>Copyright © 2020 Easy Tech Services, LLC, 500 Bayview Dr #1818, Sunny Isles Beach, FL 33160</p>
     <p></p>
     <p></p>
     <p></p>
     <h2 id="Additional-Terms-for-Business-Accounts-"><strong>Additional Terms for Business Accounts&nbsp;</strong></h2>
     <p></p>
     <p></p>
     <p><strong>Last Updated on December 13, 2019.</strong></p>
     <p></p>
     <p></p>
     <p>The following terms (“<strong>Business Terms</strong>”), in addition to the Terms of Service above, govern your access to and use of your Business Account. In the event of any conflict between these Business Terms and the Terms of Service, the Business Terms apply. If you have purchased products or services from Carries' Find on behalf of your business (e.g., advertising or business tools), the terms of that purchase apply in the event of any conflict with these Business Terms. Capitalized words used but not defined in these Business Terms have the meanings described in the Terms of Service. By creating, accessing, or using your Business Account, you are agreeing to these Business Terms and concluding a legally binding contract with Carries' Find. You are not authorized to create, access, or use a Business Account if you do not agree to these Business Terms.&nbsp;</p>
     <p></p>
     <p></p>
     <p><strong>PLEASE READ THESE BUSINESS TERMS CAREFULLY AS THEY REQUIRE THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN TRIALS OR CLASS ACTIONS, AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE</strong>.&nbsp;</p>
     <p></p>
     <p></p>
     <p>In the event of any termination of these Business Terms, whether by you or us, these Business Terms in their entirety will continue in full force and effect.</p>
     <p></p>
     <p></p>

     <ol>
        <li>
           <strong>REQUIREMENTS, REPRESENTATIONS AND WARRANTIES</strong>
           <ol>
              <li>
                 In order to access or use the Services, you agree that:
                 <ol>
                    <li>you have the authority to act on behalf of the business or businesses associated with or claimed through your Business Account and bind any such business (including any corresponding business entity) to the Business Terms (such business or businesses, your “<strong>Business</strong>”);</li>
                    <li>your access to or use of the Business Site will only be in your capacity as an authorized representative of your Business;</li>
                    <li>you will not use the Consumer Site for business activities, including but not limited to flagging reviews or messaging people who have reviewed your Business;</li>
                    <li>your Business complies with applicable laws and does not offer, advertise, sell, or lease illegal products and/or services;</li>
                    <li>you grant Carries' Find a non-transferable, non-exclusive, royalty-free limited license to display your public website on the Services, or allow for its display through iframes or other framing technology;</li>
                    <li>you agree that we may contact you, including by phone or email, using the contact information you provide us, make publicly available, or that we have on record for your business, and that our communications (including phone calls) with you may be monitored and recorded for quality purposes;</li>
                    <li>you understand that we may display health score information for your Business, and may place a Consumer Alert regarding that health score, on the business page for your Business;</li>
                    <li>you understand and agree that we may share certain aggregate or otherwise deidentified information about your responses to Request a Quote leads with other Businesses, for example, the number of total Businesses responding to the lead and the speed of those responses; and</li>
                    <li>you understand and acknowledge that non-disparagement clauses in certain consumer contracts, such as clauses that seek to restrict or prohibit reviews (including provisions that penalize consumers for posting reviews) about your Business, are prohibited under California law (Cal. Civil Code § 1670.8) and under the federal Consumer Review Fairness Act (15 U.S. Code § 45b) and you agree that you will not include such clauses in your consumer contracts, or otherwise attempt to enforce non-disparagement or ‘gag’ clauses against consumers under any circumstances. You understand that we may publicly notify consumers, including by placing a Consumer Alert on the business page for your Business, if we have a good faith belief that such clauses are used by your Business.</li>
                 </ol>
              </li>
              <li>
                 You represent and warrant that you will not, and will not authorize or induce any other party, to:
                 <ol>
                    <li>offer incentives of any kind, such as discounts, freebies, refunds, gift cards, contest entries, offers, or deals in exchange for the posting of reviews of your Business, or to prevent or remove reviews, and you understand and acknowledge that Carries' Find, through its Consumer Alerts, may publicly notify consumers about such incentives and other attempts to obtain, prevent, or remove reviews;</li>
                    <li>solicit or ask for reviews from your customers;</li>
                    <li>write reviews or vote on Content (e.g., voting user reviews as useful, funny, or cool) for your Business or your Business’s competitors;</li>
                    <li>pay or induce anyone to post, refrain from posting, or remove reviews, or otherwise attempt to circumvent Carries' Find’s Recommendation Software (defined below) or fraud detection systems;</li>
                    <li>attempt to generate automated, fraudulent, or otherwise invalid ad impressions, inquiries, conversions, ad clicks, or other actions;</li>
                    <li>use any automated means or form of scraping or data extraction to access, query or otherwise collect Carries' Find data, content and/or reviews from the Consumer Site or the Business Site, except as expressly permitted by Carries' Find (for example, as described at www.Carries' Find.com/robots.txt);</li>
                    <li>use any Carries' Find trademark or service mark in any manner without Carries' Find’s prior written consent; or</li>
                    <li>misrepresent your identity or affiliation to anyone in connection with Carries' Find.</li>
                 </ol>
              </li>
              <li>
                 You understand and acknowledge that Carries' Find allows consumers to post Content about your Business, including photos, ratings, and reviews. You understand and acknowledge that Carries' Find employs automated software in an effort to showcase the most reliable and useful reviews while displaying other reviews less prominently (<strong>“Recommendation Software”</strong>). You understand and acknowledge that while Carries' Find uses its Recommendation Software to identify potentially less helpful reviews, the Recommendation Software may sometimes suppress legitimate reviews or fail to detect illegitimate reviews. You understand and acknowledge that any purchase of advertising or other paid features from Carries' Find will not influence the Recommendation Software or otherwise allow or enable You, directly or indirectly, to alter reviews or impact whether, where, or how reviews appear on Carries' Find.
                 <p><strong>The following Sections 2 and 3 apply if you are a resident of </strong><strong>the United States or Canada only:</strong></p>
              </li>
           </ol>
        </li>
        <li><strong>DISCLAIMERS AND LIMITATIONS OF LIABILITY<br/></strong><strong>PLEASE READ THIS SECTION CAREFULLY SINCE IT LIMITS THE LIABILITY OF THE Carries' Find ENTITIES TO YOU. FOR CLARITY, THE BELOW APPLIES IN ADDITION TO THE DISCLAIMERS AND LIMITATIONS OF LIABILITY DETAILED IN SECTION 12 OF THE TERMS.<br/></strong>The federal Communications Decency Act (<a href="https://www.law.cornell.edu/uscode/text/47/230">47 U.S. Code § 230) </a>limits the liability of interactive computer services, like Carries' Find, for their role in publishing third-party Content, including consumer reviews. Additionally, anti-SLAPP laws, such as <a href="https://codes.findlaw.com/ca/code-of-civil-procedure/ccp-sect-425-16.html">Cal. Civ. Proc. Code&nbsp; § 425.16</a> in California, may require you to pay Carries' Find’s attorneys’ fees if you attempt to impose such liability on Carries' Find through legal proceedings.</li>
        <li>
           <strong>ARBITRATION, DISPUTES, AND CHOICE OF LAW<br/></strong><strong>FOR CLARITY, THIS SECTION GOVERNS ANY BUSINESS CLAIM BROUGHT BY YOU OR Carries' Find. ANY CLAIM NOT SUBJECT TO THIS SECTION IS INSTEAD GOVERNED BY SECTION 13 OF THE TERMS.<br/></strong>Except for Excluded Business Claims, any controversy or claim arising out of or relating to: (a) these Terms, or the breach thereof; or (b) your access to or use of your Business Account Business Site; (each such controversy or claim, a “<strong>Business</strong> <strong>Claim</strong>”), shall be settled by arbitration administered by the American Arbitration Association in accordance with its Commercial Arbitration Rules and judgment on the award rendered by the arbitrator may be entered in any court having jurisdiction thereof. The arbitrator will issue a reasoned award in writing, including all findings of fact and law upon which the award was made. The arbitrator will not have the power to commit errors of law, and the award may be vacated or corrected through judicial review by a court of competent jurisdiction under the California Arbitration Act for any such error. “<strong>Excluded Business Claims</strong>” means Claims related to intellectual property (like copyrights and trademarks) or violations of Section 7 of the Terms of Service (Representations and Warranties).
           <p>Business Claims shall be heard by a single arbitrator. Arbitrations will be held in San Francisco, California, but the parties may choose for themselves whether to appear in person, by phone, or through the submission of documents. The arbitration shall be governed by the laws of the State of California. The prevailing party shall be entitled to an award of reasonable attorneys’ fees.</p>
           <p>NOTWITHSTANDING THE FOREGOING, FOR ANY BUSINESS CLAIM THAT IS NOT SUBJECT TO ARBITRATION, INCLUDING WITHOUT LIMITATION EXCLUDED BUSINESS CLAIMS, YOU AGREE TO SUBMIT AND CONSENT TO THE PERSONAL AND EXCLUSIVE JURISDICTION IN, AND THE EXCLUSIVE VENUE OF, THE STATE AND FEDERAL COURTS LOCATED WITHIN SAN FRANCISCO COUNTY, CALIFORNIA, WHICH IS THE PLACE OF PERFORMANCE OF THESE BUSINESS TERMS.</p>
           <p>YOU AND Carries' Find AGREE THAT EACH MAY BRING OR PARTICIPATE IN BUSINESS CLAIMS AGAINST THE OTHER ONLY IN THEIR RESPECTIVE INDIVIDUAL CAPACITIES, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. UNLESS BOTH YOU AND Carries' Find AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN THE BUSINESS CLAIMS OF OTHER PERSONS OR PARTIES WHO MAY BE SIMILARLY SITUATED, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING. IF A BUSINESS CLAIM IMPLICATES THIS SECTION, AND THIS SECTION IS FOUND TO BE INVALID, UNENFORCEABLE OR ILLEGAL BY A COURT, SUCH BUSINESS CLAIM MUST BE ADJUDICATED BY A COURT AND NOT BY AN ARBITRATOR.&nbsp;</p>
        </li>
     </ol>


  </div>
);

export default Terms;
